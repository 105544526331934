import { createSlice,current } from '@reduxjs/toolkit'


export const StatusConditionSlice = createSlice({
  name: 'StatusCondition',
  initialState: { statusCondition: [],groupSelected:[], refrenceSelected:[],question:undefined,siteId:undefined}
  ,
  reducers: {
    saveCondition: (state, action) => {
      //state.conditionResult=[...state.conditionResult,{key:action,value:{name:"seo",value:false,connect:(action.payload===0?'':'or')}}]
      state.statusCondition.push(action.payload);
    },
    removeCondition: (state, action) => {
      var newItems = state.statusCondition.filter((item) => item.key != action.payload);
      state.statusCondition = newItems;
    },
    changeConditionValue: (state, action) => {
      const currentItem = state.statusCondition.find((item) => item.key === action.payload.key)    
      currentItem[action.payload.property]=action.payload.value;                     
    },
    resetCondition:(state)=>{
      state.statusCondition =[];
    },
    setGroupSelectedStore:(state,action)=>{
      state.groupSelected=action.payload;      
    },
    setRefrenceSelectedStore:(state,action)=>{
      state.refrenceSelected=action.payload;
    },
    setQuestionStore:(state,action)=>{
      state.question=action.payload;
    },
    setSiteIdStore:(state,action)=>{
      state.siteId=action.payload;
    }
  },
})

export const { saveCondition, changeConditionValue,
   removeCondition,resetCondition,setGroupSelectedStore,setRefrenceSelectedStore,setQuestionStore,setSiteIdStore } = StatusConditionSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const removeCondition = (key) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectConditionResult = (state) => state.statusConditionSliceReducer.statusCondition
export const getAllSearchOption=(state)=>{ 
  return{statusCondition:state.statusConditionSliceReducer.statusCondition,groupSelected:state.statusConditionSliceReducer.groupSelected,
  refrenceSelected:state.statusConditionSliceReducer.refrenceSelected,
  question:state.statusConditionSliceReducer.question,
  siteId:state.statusConditionSliceReducer.siteId,
}}
//export const selectConditionResultById = (state, action) => state.filter((item) => item.key.payload == action.payload)
export default StatusConditionSlice.reducer
