import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import "./Help.scss";
const Help = () => {
  const matchWebsiteHin = useSelector(
    getTranslate("ResultRow_MatchWebsite_Hint")
  );
  const notMatchWebsiteHint = useSelector(
    getTranslate("ResultRow_Not_MatchWebsite_Hint")
  );
  const notExistsInWebsiteHint = useSelector(
    getTranslate("ResultRow_NotExists_InWebsite_Hint")
  );
  const isSeperatedHint = useSelector(
    getTranslate("ResultRow_Is_Seperated_Hint")
  );

  return (
    <div className="d-flex flex-column published-status">
      <div className="d-flex published-status-not-match">
        <div className="published-status__item--size published-status-not-match--back-color-gray"></div>
        <h5 className="published-status-not-match--item">
          {notMatchWebsiteHint}
        </h5>
      </div>
      <div className="d-flex published-status-match">
        <div className="published-status__item--size published-status-match--back-color-green"></div>
        <h5 className="published-status-not-match--item">{matchWebsiteHin}</h5>
      </div>

      <div className="d-flex published-status-not-exists">
        <div className="published-status__item--size published-status-not-exists--back-color-red"></div>
        <h5 className="published-status-not-match--item">
          {notExistsInWebsiteHint}
        </h5>
      </div>
      <div className="d-flex published-status-match">
        <div className="published-status__item--size published-status-match--back-color-orange"></div>
        <h5 className="published-status-not-match--item">{isSeperatedHint}</h5>
      </div>
    </div>
  );
};

export default Help;
