import React, { useState, useEffect } from "react";
// import Multiselect from 'multiselect-react-dropdown';
import QuestionGroupsService from "../../_services/group.service";
import RefrenceTypeService from "../../_services/refrenceType.service";
import "./SearchOption.scss";
import { MultiSelect } from "react-multi-select-component";
import { Modal } from "react-bootstrap";
import StatuseCondition from "./StatusCondition";
import { useDispatch } from "react-redux";
import {
  setGroupSelectedStore,
  setQuestionStore,
  setRefrenceSelectedStore,
  setSiteIdStore,
  getAllSearchOption
} from "./StatusConditionSlice";
import useCollapse from "react-collapsed";
import { useSelector } from "react-redux";
import { selectConditionResult, resetCondition } from "./StatusConditionSlice";
import { addRemoveItem } from "../../_helpers/util";
// import { useMediaQuery } from "react-responsive";
import $ from "jquery";
import { getCurrentLang, getTranslate } from "../Phrase/PhraseSlice";

export default function SearchOption(props) {
  const [isExpanded, setIsExpanded] = useState(true);
  const config = {
    duration: 1000,
  };
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    config,
  });
  const historyConditionResult = useSelector(selectConditionResult);
  const [statuseCondition, setStatuseCondition] = useState("");
  useEffect(() => {
    setStatuseCondition(getStatuseCondition());
  }, [historyConditionResult]);
  
   const dispatch = useDispatch();
  const currentLang = useSelector(getCurrentLang);
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroup] = useState([]);
  const [refrenceTypes, setRefrenceType] = useState([]); 
  const [groupSelected, setGroupSelected] = useState([]);
  const [refrenceSelected, setRefrenceSelected] = useState([]);
  const [question, setQuestion] = useState();
  const [siteId, setSiteId] = useState("");
  const [autoSearch, setAutoSearch] = useState(false);

  //نمایش شروط وضعیت برای جستجو
  const [showStatuseCondition, setShowStatuseCondition] = useState(false);
  const handleCloseStatuseCondition = () => setShowStatuseCondition(false);

  // const handleShowStatuseCondition = () => setShowStatuseCondition(true);

  //پاس دادن آرایه خالی  باعث میشه تا
  // React
  // فقط یکبار در زمان
  //mount
  //کامپوننت آن را اجرا کند.
  useEffect(() => {
    setIsLoading(true);
    QuestionGroupsService.getAll()
      .then((response) => response.data)
      .then((data) => {
        setGroup(
          data.map((item) => {
            return {
              label: item.value,
              value: item.key,
            };
          })
        );
      })
      .catch();
    RefrenceTypeService.getAll()
      .then((response) => response.data)
      .then((data) => {
        setRefrenceType(
          data.map((item) => {
            return {
              label: item.value,
              value: item.key,
            };
          })
        );
      })
      .catch();
    setIsLoading(false);
  }, [currentLang]);

  useEffect(() => {
    dispatch(setGroupSelectedStore(groupSelected));
  }, [groupSelected]);

  useEffect(() => {
    dispatch(setRefrenceSelectedStore(refrenceSelected));
  }, [refrenceSelected]);

  useEffect(() => {
    dispatch(setSiteIdStore(siteId));
  }, [siteId]);

  useEffect(() => {
    dispatch(setQuestionStore(question));
  }, [question]);

  useEffect(() => {
    if(autoSearch === true)
      searchClick();
    
  }, [autoSearch]);


  const searchClick = () => {
    setAutoSearch(false)
    // props.searchOptionParameter.contentText = question;
    // props.searchOptionParameter.questionId = siteId;
    // if(refrenceSelected.length>0)
    // refrenceSelected.map((item)=>{props.searchOptionParameter.groupIdList.push(item.value)})
    // if (groupSelected.length > 0)
    //     groupSelected.map((item) => { props.searchOptionParameter.groupIdList.push(item.value) });

    props.onSearchClick();
  };
  const filterClick = () => {
    setShowStatuseCondition(true);
  };

  function filterOptions(options, filter) {
    if (!filter) {
      return options;
    }
    //const re = new RegExp(filter, "i");
    // return options.filter(({ value }) => value && value.match('re'));
    return options;
  }
  function searchKeyDown(e) {
    if (e.key == "Enter") props.onSearchClick();
  }
  function handleOnClick() {
    // Do more stuff with the click event!
    // Or, set isExpanded conditionally
    setIsExpanded(!isExpanded);
  }
  function getStatuseCondition() {
    let statuseCondition = "";
    historyConditionResult.forEach((element) => {     
      statuseCondition =
        statuseCondition +
        " " +
        [element.connect] +
        " " +
        [element.name] +
        //getTranslate([element.name]) +
        " is " +
        //getTranslate([element.value])
         [element.value];
    });
    return statuseCondition;
  }
  function test() {
    $.confirm("Are you sure ?");
    //   $.alert({
    //     title: 'هشدار',
    //     content: 'گذرواژه با موفعیت بروز رسانی شد',
    //     rtl: true,
    // });
  }
  function clearSearchOption() {
    $.confirm({
      title: "هشدار",
      content: "از خالی کردن فیلدهای جستجو مطمئن هستید؟",
      rtl: true,
      type: "red",
      animation: "top",
      buttons: {
        confirm: {
          text: "بله",
          action: function () {
            setGroupSelected((previous) => []);
            setRefrenceSelected((previous) => []);
            setSiteId("");
            dispatch(resetCondition());
            setQuestion(""); 
            setAutoSearch(true)
          },
        },
        cancel: { text: "خیر" },
      },
    });
  }
  return (
    <>
      <div className="collapsible w-100">
        <div
          className="d-flex serach-toggle-button"
          {...getToggleProps({ onClick: handleOnClick })}
          title={useSelector(
            getTranslate(
              isExpanded ? "Search_Btn_Hide_Search" : "Search_Btn_Show_Search"
            )
          )}
        >
          {/* title={isExpanded ?"پنهان کردن جستجو":"نمایش جستجو"}> */}
          {isExpanded
            ? String.fromCodePoint(0x02227)
            : String.fromCodePoint(0x02228)}
        </div>
        <div {...getCollapseProps()}>
          <div className="d-felx flex-col m-2 search-option-form w-100">
            <div className="d-lg-flex search-option">
            <div className="flex-grow-0 pr-2 " >
              <i
                className={`icon icon-filter form-control flex-grow-0 ${
                  historyConditionResult.length == 0 ? "icon-filter-gray" : ""
                } `}
                onClick={(e) => filterClick()}
                title={useSelector(getTranslate("Search_Btn_Filter_Hint"))} //"اضافه کردن شرط وضعیت"
              ></i>
              </div>
              <div className="flex-grow-0 pr-2" >
                <MultiSelect
                  className="text-right group-item "
                  options={groups}
                  value={groupSelected}
                  onChange={setGroupSelected}
                  hasSelectAll={false}
                  // closeOnChangedValue={true}
                  overrideStrings={{
                    selectSomeItems: useSelector(
                      getTranslate("Search_SelectGroup_Placeholder")
                    ), //گروه
                    allItemsAreSelected: "تمام موارد انتخاب شده اند",
                    selectAll: "انتخاب همه",
                    search: "جستجو",
                  }}
                  filterOptions={filterOptions}
                />
              </div>
              <div className="flex-grow-0 pr-2 refrenceType-item-parent">
                <MultiSelect
                  className="text-right refrenceType-item"
                  options={refrenceTypes}
                  value={refrenceSelected}
                  onChange={setRefrenceSelected}
                  hasSelectAll={false}
                  disableSearch={true}
                  overrideStrings={{
                    selectSomeItems: useSelector(
                      getTranslate("Search_SelectRefrenceType_Placeholder")
                    ), //"منبع",
                    allItemsAreSelected: "تمام موارد انتخاب شده اند",
                    selectAll: "انتخاب همه",
                    search: "جستجو",
                  }}
                />
              </div>

              <div
                className="flex-grow-0 automationid pr-2"
                onKeyDown={(e) => searchKeyDown(e)}
              >
                <input
                  type="number"
                  className="form-control"
                  id="siteId"
                  value={siteId}
                  placeholder={useSelector(
                    getTranslate("Search_IdInSiteOrId_Placeholder")
                  )} //"کد سایت/کدابزار"
                  name="siteId"
                  onChange={(e) => setSiteId(e.target.value)}
                />
              </div>

              <div
                className="flex-grow-1 input-group mb-3 pr-2 "
                onKeyDown={(e) => searchKeyDown(e)}
              >
                <input
                  className="form-control question"
                  id="question"
                  placeholder={useSelector(
                    getTranslate("Search_Question_Placeholder")
                  )} //"قسمتی از پرسش / پاسخ / عنوان ..."
                  name="question"
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                  value={question}
                />
                <div
                  className="input-group-prepend optionsearch-search"
                  onClick={(e) => searchClick()}
                >
                  <span
                    className="input-group-text search-button"
                    title={useSelector(getTranslate("Search_Btn_Search_Hint"))}
                  >
                    <i className=" icon icon-search"></i>
                  </span>
                </div>
              </div>

              <div className="d-felx input-group mb-3 justify-content-end ">
                <button type="button" className="clear-search " onClick={clearSearchOption}>
                  {useSelector(
                    getTranslate("Search_Btn_ClearSearchField_Hint")
                  )}
                </button>
              </div>
            </div>
            {addRemoveItem({
              item: (
                <div style={{ textAlign: "start" }}>{statuseCondition}</div>
              ),
              condition: statuseCondition != "",
            })}
          </div>
        </div>
      </div>

      <Modal
        show={showStatuseCondition}
        onHide={() => {
          dispatch(resetCondition());
          handleCloseStatuseCondition();
        }}
        size="sm"
        aria-labelledby=""
        className="searchoption-statuse-aria"
      >
        <Modal.Header closeButton className=" header-new-question-color">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-question-body-color">
          <StatuseCondition
            onHide={() => {
              dispatch(resetCondition());
              handleCloseStatuseCondition();
              searchClick();
            }}
            onAccept={() => {
              handleCloseStatuseCondition();
              searchClick();
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
