import React from "react"
export default function TextareaEditor(props) {   
    return (
        <div className={`form-group d-flex ${props.classNameRoot}`}>
            <label htmlFor={props.id} className={`${props.labelClass}`} hidden={props.labelHidden}>{props.children}</label>
            <div className="add-question-input-item">
                <textarea type="text" onChange={props.onChange} value={props.value}
                className="form-control text-editor-border-radius"  name={props.id} id={props.id} placeholder={props.children}></textarea>
            </div>
        </div>
    )
}
