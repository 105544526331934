import http from "./http-common";

class HistoricalEvent { 
  async getAll() {       
   const respons= await http.get(`HistoricalEvent/GetByLanguageId`);    
    return respons
  }  
  
}

export default new HistoricalEvent();