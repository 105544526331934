/* import { useState } from 'react';

const UserToken= ()=> {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };


  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}
export default UserToken; */

class TokenHelper {
  
  diffDays(date, otherDate) {
     return Math.ceil(date - otherDate / (1000 * 60 * 60 * 24));
    }
  getUserToken() {    
    const tokenString = localStorage.getItem('userToken');
    if(tokenString==null)
    return null;
    const userToken = JSON.parse(tokenString);
     // To set two dates to two variables
     var currentDate = new Date();
     var expirationDate = new Date(userToken.expirationDate);
    
     // To calculate the time difference of two dates
     if( currentDate.getTime()>expirationDate.getTime())
     {
      localStorage.removeItem('userToken');
     return null;    
     }

    return userToken;
  }
  removeItem(key)
  {
    localStorage.removeItem(key);
  }
  setUserToken(userToken) {
    localStorage.setItem('userToken', JSON.stringify(userToken));
  }
  getToken() {
    const tokenString = localStorage.getItem('userToken');   
    if (tokenString == null)
      return "";     
    const tmp = JSON.parse(tokenString);        
    var token = tmp.token
    return token;
  }
}
export default new TokenHelper();