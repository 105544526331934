import React, { useState, useEffect } from "react";
import ResultRow from "./ResultRow";
import SearchOption from "./SearchOption";
import QuestionService from "../../_services/question.service";
import "./SearchResult.scss";
import Pagination from "../../components/Pagination/Pagination";
import "../../iconstyle.scss";
import { Modal } from "react-bootstrap";
import AddNewQuestion from "../Question/AddNewQuestion";
import OneSelect from "../Templates/OneSelect";
import { useSelector } from "react-redux";
import { getAllSearchOption } from "./StatusConditionSlice";
import { useMediaQuery } from "react-responsive";
import { getCurrentLang, getTranslate, setCurrentLang } from "../Phrase/PhraseSlice";
import { decode } from "html-entities";
import Help from "../Help/Help";
import { parseFarsiNumber, showConfirm, showDialog, showLoadingDialog } from "../../_helpers/util";
import { useNavigate, useLocation, Route, Routes } from "react-router-dom";
export default function SearchResult() {
  const navigate = useNavigate();
  const location = useLocation();
  //نمایش شروط وضعیت برای کمک
  const [showHelp, setShowHelp] = useState(false);
  const handleCloseHelp = () => setShowHelp(false);

  const currentLang = useSelector(getCurrentLang);
  //نمایش ویرایش و اضافه کردن سوال
  const [showAddQuestion, setShowAddQuestion] = useState(false);

  const [orderType, SetOrderType] = useState("ASC");
  const allSearchOption = useSelector(getAllSearchOption);

  const [actionType, setActionType] = useState("");
  const [currentId, setCurrentId] = useState(-1);

  const handleCloseAddQuestion = () => {
    navigate(-1)
    setShowAddQuestion(false);
  };
  const handleShowAddQuestion = () => {
    setShowAddQuestion(true);
  }

  const oneSelectOptions = [
    { key: "8", value: "8" },
    { key: "40", value: "40" },
    { key: "72", value: "72" },
  ];

  const [results, setData] = useState({
    items: [],
    totalCount: 0,
  });
  const [currentPage, setcurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(8);
  const [searchOptionParameter, setSearchOptionParameter] = useState({
    questionId: 0,
    groupIdList: [],
    contentText: "",
    refrenceTypeIdList: [],
    currentPage: 1,
    countPerPage: 8,
    statusConditions: [],
    orderType: "ASC",
  });
  const [chooseId, SetChooseId] = useState([]);
  const addNewQuestionId = useSelector(
    getTranslate("AddNewQuestion_Id_Placeholder")
  );

  const isScreen973 = useMediaQuery({ query: "(min-width: 973px)" }); //flex-md
  useEffect(() => { }, [isScreen973]);

  //پاس دادن آرایه خالی  باعث میشه تا
  // React
  // فقط یکبار در زمان
  //mount
  //کامپوننت آن را اجرا کند.
  useEffect(() => {
    // searchClick();
  }, []);

  const searchClick = async () => {
    var loading = showLoadingDialog();
    console.log('searchOptionParameter1', searchOptionParameter);
    setData({ items: [], totalCount: 0 });
    console.log('searchOptionParameter2', searchOptionParameter);
    searchOptionParameter.currentPage = currentPage;
    searchOptionParameter.countPerPage = countPerPage;
    if (searchOptionParameter.contentText == undefined)
      searchOptionParameter.contentText = "";
    if (
      searchOptionParameter.questionId == undefined ||
      searchOptionParameter.questionId == ""
    )
      searchOptionParameter.questionId = 0;
    console.log('searchOptionParameter3', searchOptionParameter);

    await QuestionService.getByCondition(searchOptionParameter).then(response => {
      if (response?.status) {
        loading.close();
        setData({
          items: response.data,
          totalCount:
            response.data != undefined && response.data.length != 0 ? response.data[0].totalCount : 0,
        });
      }
      else {
        loading.close();
        setData({
          items: [],
          totalCount: 0,
        });

      }
    }).catch(ex => {
      setData({
        items: [],
        totalCount: 0,
      });
      loading.close();
      console.log(ex);
      showDialog("", "مشکلی در دریافت نتیجه پیش آمده است", "red", 1000);
    }
    );


  };

  useEffect(() => {
    searchClick();
  }, [currentPage, countPerPage, currentLang]);

  const searchOptionClick = () => {
    searchOptionParameter.contentText = allSearchOption.question;
    searchOptionParameter.questionId = allSearchOption.siteId;
    searchOptionParameter.refrenceTypeIdList = [];
    if (allSearchOption.refrenceSelected.length > 0)
      allSearchOption.refrenceSelected.map((item) => {
        searchOptionParameter.refrenceTypeIdList.push(item.value);
      });

    searchOptionParameter.groupIdList = [];

    if (allSearchOption.groupSelected.length > 0)
      allSearchOption.groupSelected.map((item) => {
        searchOptionParameter.groupIdList.push(item.value);
      });

    searchOptionParameter.statusConditions = [];
    if (allSearchOption.statusCondition.length > 0) {
      allSearchOption.statusCondition.map((item) => {
        searchOptionParameter.statusConditions.push({
          name: item.name,
          value: item.value,
          connect: item.connect
        });
      });
    }
    setcurrentPage(1);
    searchClick();
  };

  //علامت زدن سوال برای حذف
  const handleCheckedForDelete = (e) => {
    if (e.target.checked) {
      SetChooseId((oldArray) => [...oldArray, e.target.id]);
    } else SetChooseId(chooseId.filter((item) => item !== e.target.id));
  };

  //حذف سوال
  const handleDeleteQuestion = () => {
    if (chooseId.length !== 0) {
      showConfirm("هشدار", `از حذف سوال های ${chooseId} مطمئن هستید؟\n توجه داشته باشید : این سوال ها از سایت اصلی حذف خواهند شد.`, "red", deleteQuestions, "");
    } else {
      showDialog("هشدار", `<span class="styled">${"هیچ سوالی برای حذف انتخاب نشده است."}</span>`, "red", 1000);
    }
  };
  const deleteQuestions = () => {
    QuestionService.delete(chooseId).then((response) => {
      if (response.status) {
        let resulttmp = results.items;
        let result = resulttmp.filter(
          (t) => !chooseId.some((id) => t.questionsArchiveId == id) //===به اشکال می خورد چون علاوه بر مقدار نوع را هم بررسی می کند
        );
        SetChooseId([]);
        setData((prevState) => ({
          ...prevState,
          items: result,
        }));
      }
      showDialog("هشدار", response.data, "green", 1000);
    });
  };

  const onClickAddQuestionHandler = () => {
    setActionType("Add");
    handleShowAddQuestion();
    navigate(window.location.pathname + "/addNewQuestion");
  };

  const editClickHandler = (id) => {
    setCurrentId(id);
    setActionType("Edit");
    setShowAddQuestion(true);
    navigate(window.location.pathname + "/editQuestion");
  };

  const handleSaveQuestion = (question) => {
    navigate(-1)   //   lajevardi-14030430: Edit rout when Save EditQuestion
    setShowAddQuestion(false);
    if (actionType === "Add") {
      setData((prev) => ({
        ...prev,
        items: [question, ...prev.items],
      }));
    } else {
      //let newArr = [...results.items]; // copying the old datas array
      const updatedItems = results.items.map((item) =>
        item.questionsArchiveId === question.questionsArchiveId ? question : item
      );
      setData((prev) => ({
        ...prev,
        items: updatedItems,
      }));
      showDialog("", "تغییرات با موفقعیت ذخیره شد", "green", 1000);
    }
  };

  function codeOnclick() {
    if (orderType == "ASC") {
      searchOptionParameter.orderType = "DESC";
      SetOrderType("DESC");
    } else {
      SetOrderType("ASC");
      searchOptionParameter.orderType = "ASC";
    }

    searchClick();
  }

  return (
    <>
      <div className="container-fluid d-flex flex-column result-page">
        {/* Search component */}
        <div className="d-flex flex-grow-0">
          <SearchOption
            onSearchClick={searchOptionClick}
            searchOptionParameter={searchOptionParameter}
          />
        </div>

        {/* result count per page */}
        <div className="d-flex flex-row-reverse flex-grow-0 result-body-header">
          <div className=" d-flex flex-grow-0 border-right align-items-center">
            <label className="m-1">
              {useSelector(
                getTranslate("SearchResult_Expression_CountPerPage")
              )}
              {/* تعداد نمایش در صفحه{" "} */}
            </label>
            <OneSelect
              className="d-flex flex-grow-0 align-items-center count-per-page"
              id="countPerPage"
              options={oneSelectOptions}
              onChange={(e) => setCountPerPage(e.target.value)}
            ></OneSelect>
          </div>
          <span
            className=" d-flex flex-grow-0   align-items-center"
            onClick={handleDeleteQuestion}
            title={useSelector(
              getTranslate("SearchResult_Btn_DeleteQuestion_Hint")
            )}
          // "حذف پرسش های انتخاب شده"
          >
            <span className="icon icon-btn-delete searchresult-icon-delete">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span>
          </span>
          <span
            className="d-flex flex-grow-0  align-items-center"
            onClick={onClickAddQuestionHandler}
            title={useSelector(
              getTranslate("SearchResult_Btn_AddQuestion_Hint")
            )}
          // "افزودن پرسش و پاسخ"
          >
            <span className="icon icon-add-question searchresult-icon-add">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span>
          </span>
          <div
            className="d-flex align-items-center justify-content-center help mr-3"
            onClick={() => setShowHelp(true)}
          >
            ?
          </div>
        </div>
        {/* result header */}
        <div className="result-body">
          <div className="p-2 d-flex rounded-top headercolor header-search-result align-items-center justify-content-center flex-grow-0 ">
            <span
              className="d-flex justify-content-center align-items-center code"
              onClick={codeOnclick}
            >
              {useSelector(getTranslate("SearchResult_Word_Code"))}
              {orderType == "ASC" ? decode("&#8593;") : decode("&#8595;")}
              {/* کد */}
            </span>
            <span className="d-flex justify-content-center align-items-center group">
              {useSelector(getTranslate("SearchResult_Word_Group"))}
              {/* گروه */}
            </span>
            <span className="d-flex justify-content-center align-items-center title">
              {useSelector(getTranslate("SearchResult_Word_Title"))}
              {/* عنوان */}
            </span>
            <span
              className={`d-flex justify-content-center align-items-center ${isScreen973 ? " flex-grow-1" : "flex-grow-1"
                }`}
            >
              {useSelector(getTranslate("SearchResult_Word_QuestionAnswer"))}
              {/* پرسش/پاسخ */}
            </span>
            <span className="d-flex justify-content-center align-items-center status">
              {useSelector(getTranslate("SearchResult_Word_Status"))}
              {/* وضعیت */}
            </span>
            <span className="d-flex justify-content-center align-items-center action">
              {useSelector(getTranslate("SearchResult_Word_Action"))}
              {/* عملیات */}
            </span>
          </div>
          {/* body */}
          <div className="border bordercolor   flex-grow-1">
            {results.items.map((item, index) => (
              <ResultRow
                item={item}
                key={item.questionsArchiveId}
                index={index}
                editClick={() => editClickHandler(item.questionsArchiveId)}
                deleteActionHide={true}
                handleCheckedForDelete={handleCheckedForDelete}
                textTruncate={"resultrow-replaytext-truncate"}
              />
            ))}
          </div>
        </div>

        {/* footer */}
        <div className="d-md-flex flex-row pb-2 pt-1 flex-grow-0">
          <div className="d-flex search-result-count-section align-items-center border">
            <span className="pl-2 pr-2">
              {useSelector(getTranslate("SearchResult_Expression_ResultCount"))}
              {/* تعداد پاسخ  */}
            </span>
            <span className="d-flex flex-grow-1  pl-2 pr-2 search-result-count">
              {results.totalCount}
            </span>
          </div>

          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <Pagination
              className="pagination-bar"
              currentPage={searchOptionParameter.currentPage}
              totalCount={results.totalCount}
              pageSize={searchOptionParameter.countPerPage}
              onPageChange={(page) => setcurrentPage(page)}
            />
          </div>
        </div>
      </div>
      <Routes>
        <Route path={`${actionType === "Add" ? "addNewQuestion" : "editQuestion"}`} element={<Modal
          show={showAddQuestion}
          onHide={handleCloseAddQuestion}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          className="addquestion-width"
          enforceFocus={false}
        >
          <Modal.Header closeButton className=" header-new-question-color">
            <Modal.Title>

              {useSelector(
                actionType === "Add"
                  ? getTranslate("AddNewQuestion_Expression_AddHeader")
                  : getTranslate("AddNewQuestion_Expression_EditHeader")
              )}
              {actionType === "Add"
                ? ""
                : " - " + addNewQuestionId + " : " + parseFarsiNumber(currentId.toString())}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-body-color">
            <AddNewQuestion
              id={currentId}
              actionType={actionType}
              onClickSave={handleSaveQuestion}
              onClickCancel={handleCloseAddQuestion}
            />
          </Modal.Body>
        </Modal>} />
      </Routes>
      {/*  <Modal
        show={showAddQuestion}
        onHide={handleCloseAddQuestion}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        className="addquestion-width"
        enforceFocus={false}
      >
        <Modal.Header closeButton className=" header-new-question-color">
          <Modal.Title>           
            {useSelector(
              actionType === "Add"
                ? getTranslate("AddNewQuestion_Expression_AddHeader")
                : getTranslate("AddNewQuestion_Expression_EditHeader")
            )}
            {actionType === "Add"
              ? ""
              : " - " + addNewQuestionId + " : " + parseFarsiNumber(currentId.toString())}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-question-body-color">
          <AddNewQuestion
            id={currentId}
            actionType={actionType}
            onClickSave={handleSaveQuestion}
            onClickCancel={handleCloseAddQuestion}
          />
        </Modal.Body>
      </Modal> */}
      <Modal
        show={showHelp}
        onHide={handleCloseHelp}
        size="sm"
        aria-labelledby=""
        className="searchoption-statuse-aria"
      >
        <Modal.Header closeButton className=" header-new-question-color">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-question-body-color">
          <Help />
        </Modal.Body>
      </Modal>
    </>
  );
}
