import http from "./http-common";

class QuestionService {
  async publish(questionsArchiveListId)
  {
    const respons = await http.post(`Question/Publish`, questionsArchiveListId);
    return respons;
  }
  async getByCondition(searchOption) {
    const respons = await http.post(`Question/FindByCondition`, searchOption);    
    return respons;
  }
  async getChangedByCondition(searchOption) {
    const respons = await http.post(`Question/FindChangedByCondition`, searchOption);    
    return respons;
  }
  async getById(id) {
    const respons = await http.get(`Question/GetById?id=${id}`);
    return respons;
  }
  async getByParentId(parentId) {
    const respons = await http.get(
      `Question/GetByParentId?parentId=${parentId}`
    );
    return respons;
  }
  async insert(questionsArchive) {
    const respons = await http.post(`Question/Insert`, questionsArchive);
    return respons;
  }
  async edit(questionsArchive) {
    const respons = await http.post(`Question/Edit`, questionsArchive);
    return respons;
  }
  async delete(questionsArchiveListId) {
    const respons = await http.post(`Question/Delete`, questionsArchiveListId);
    return respons;
  }
  async completedSeparation(id) {
    const respons = await http.post(`Question/CompletedSeparation?id=${id}`);
    return respons;
  }
  async getAnswerQuestionImage(path) {
    const respons = await http.get(
      `Question/DownloadAnswerQuestionImage?path=${path}`
    );
    return respons;
  }
  async getAllAnswerQuestionImage(questionsArchiveId) {
    const respons = await http.get(
      `Question/DownloadAllAnswerQuestionImage?id=${questionsArchiveId}`
    );
    return respons;
  }
  async getTopListById(questionsArchiveId) {
    const respons = await http.get(
      `Question/GetTopicListById?questionsArchiveId=${questionsArchiveId}`
    );
    return respons;
  }

  async insertDeletTopic(
    questionsArchiveId,
    questionTopicDtoInsert,
    questionTopicDtoDelete
  ) {
    const respons = await http.post(`Question/InsertDeleteTopic`, {
      questionsArchiveId: questionsArchiveId,
      questionTopicDtoInsert: questionTopicDtoInsert,
      questionTopicDtoDelete: questionTopicDtoDelete,
    });
    return respons;
  }
}

export default new QuestionService();
