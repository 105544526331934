import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./Pagination.scss";
import { toNumber } from "lodash";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const goToPage=useSelector(getTranslate("SearchResult_Expression_GoPage"));  
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  function searchKeyDown(e) {
    if (e.key == "Enter") {
      let result = document.getElementById("gopage").value;
      if (result != "" && result != undefined) onPageChange(toNumber(result));
    }

    //onPageChange(currentPage + 1);
  }
  const hasGoPageInDocument = () => {
    let result = document.getElementById("gopage");
    return result === null;
  };

  let lastPage =paginationRange[paginationRange?.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow right" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (paginationRange.length>5 && index ==Math.round( paginationRange.length / 2) ) {
       return( <li className="" key={index}>  <input id="gopage"  type="number" className="pagination-item go-page" placeholder={goToPage}  onKeyDown={(e) => searchKeyDown(e)} /></li>)
        } else if (pageNumber === DOTS) {
          if (!hasGoPageInDocument())
            return (
              <li className="pagination-item dots" key={index}>
                &#8230;{" "}
              </li>
            );
          else
            return (
              <li className="pagination-item dots" key={index}>
                &#8230;
              </li>
            );
        }

        return (
          <li
            key={index}
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="arrow left" />
      </li>
    </ul>
  );
};

export default Pagination;
