import React, { useState } from "react";
import "./Login.scss";
import AccountService from "../../_services/account.service";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import { showDialog } from "../../_helpers/util";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";


export default function Register() {
  const [name, setName] = useState();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const account = {
      accountName: username,
      password: password,
      Name: name,
      IsAdmin: isAdmin 
    };
    await AccountService.register(account).then((response) => {
      if (response.status) {
        showDialog("", "کاربر جدید اضافه شد", "green", 500);
        navigate(`/`);
      } else {
        alert(response.data);
      }
    });
  };

  return (
    <div className="loginform">
      <div className="p-3 rounded-top headercolor"></div>
      <div className="border  p-3 rounded-bottom bordercolor">

        <div className="form-check">
          <label className="form-check-label" htmlFor="flexCheckDefault">کاربر ادمین</label>
          <input
            className="check-input"
            type="checkbox"
            name="IsAdmin"
            id="IsAdmin"
            onChange={(e) => setIsAdmin(e.target.checked)
            }
          />
        </div>

        <div className="input-group mb-3">
          <input
            className="form-control"
            id="name"
            placeholder={useSelector(getTranslate("Register_Name_Placeholder"))}
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              {useSelector(getTranslate("Register_Name_Placeholder"))}
            </span>
          </div>
        </div>

        <div className="input-group mb-3">
          <input
            className="form-control"
            id="accountName"
            placeholder={useSelector(
              getTranslate("Register_AccountName_Placeholder")
            )}
            name="accountName"
            onChange={(e) => setUserName(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              {useSelector(getTranslate("Register_AccountName_Placeholder"))}
            </span>
          </div>
        </div>
        <div className="input-group mb-3">
          <input
            type="password"
            className="form-control"
            id="pwd"
            placeholder={useSelector(
              getTranslate("Register_Password_Placeholder")
            )}
            name="pswd"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              {useSelector(getTranslate("Register_Password_Placeholder"))}
            </span>
          </div>
        </div>

        <button onClick={handleSubmit} className="btn btn-primary">
          {useSelector(getTranslate("Register_Btn_Register"))}
        </button>
      </div>
    </div>
  );
}
/* Login.propTypes = {  
  setToken: PropTypes.func.isRequired
}; */
