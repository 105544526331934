import React, { Fragment, useEffect, useState } from "react"; //, { Component } from 'react';
import { Route, Routes, useNavigate,Navigate } from "react-router-dom";

import "./App.scss";
import Home from "../components/Home/Home";
import Login from "../components/Account/Login";
import Register from "../components/Account/Register";

import Navigation from "../components/Navigation/Navigation";
import PrivateRoute from "../components/Account/PrivateRoute";
import {
  fetchTranslate,
  setCurrentLang,
} from "../components/Phrase/PhraseSlice";
import { useDispatch } from "react-redux";

import ChangePassword from "../components/Account/ChangePassword";
import { getLanguage, setLanguage } from "../_helpers/util";
import NotFound from "../components/NotFound/NotFound";
import { changeLang } from "../_services/http-common";
import QuestionsChanged from '../components/Question/QuestionsChanged'
import { debug } from "webpack";
import QuestionPublishResult from "../components/Question/QuestionPublishResult";





/* class App extends Component {

 
  render() {    
    const [token, setToken] = useToken();   
    return (
      
      <div className="App">
       
          <Login  />
       
        <h1>Test</h1>
      </div>
    );
  }
} */
function App() {
  const dispatch = useDispatch(); 
  let lang = getLanguage();
  if (lang == null) {
    setLanguage("fa");
    lang = "fa";
  }
  //var userLanguage = window.navigator.userLanguage || window.navigator.language;
  //const dispatch = useDispatch()
  //const currentURL = window.location.href; // returns the absolute URL of a page
  const navigate = useNavigate();
  const pathname = window.location.pathname; //returns the current url minus the domain name
  const pathNameSplit = pathname.split("/");  
  console.log(pathname);
  
  if(pathNameSplit[1].length==2)
  {
    if(pathNameSplit[1]!=lang)
    {
      if(pathNameSplit[1]=="ar" || pathNameSplit[1]=="fa")
      {
        lang=pathNameSplit[1];
        setLanguage(lang);        
        dispatch(setCurrentLang(lang)); 
        changeLang(lang);
      }
    }
  }   

    // useEffect( ()=>     
    //     {
    //       if (pathNameSplit[1] == "")         
    //       navigate(`/${lang}` + pathname);         
    //       return;
    //     }
    //    );  
  useEffect(() => { 
    // if (pathNameSplit[1] == "")        
    // {
    //   navigate(`/${lang}`+pathname);
    // }   
    dispatch(setCurrentLang(lang));
    dispatch(fetchTranslate());    
  }, []);


  
  //آدرس باید اصلاح شود
  //history.replaceState()

  return (
    // <BrowserRouter>
    <Fragment>      
      {/* {!notFound && <Navigation />} */}
      <Navigation />
      <Routes>      
           <Route  path={`/`} element={<Navigate to={`${lang}/questionManager`}/>}/>                             
        <Route  path={`${lang}/login`} element={<Login />} />
        <Route
         
          path={`${lang}/register`}
          element={
            <PrivateRoute redirectTo={`${lang}/login`} path="register">
              <Register />
            </PrivateRoute>
          }
        />
         <Route
          
          path={`${lang}/questionsChanged`}
          element={
            <PrivateRoute redirectTo={`${lang}/login`} path="questionsChanged">
              <QuestionsChanged/>
            </PrivateRoute>
          }
        />
        <Route         
          path={`${lang}/questionManager/*`}   //   اگر کاراکتر ستاره حذف شود، روت به صفحه ویرایش سوال خراب می شود.
          element={
            <PrivateRoute redirectTo={`${lang}/login`} path="question">
              <Home />
            </PrivateRoute>
          }
        />
        <Route   path={`${lang}/`}  element={<Navigate to={`/questionManager`}/>} />
        
       
        <Route
          path={`${lang}/changePassword`}
          element={
            <PrivateRoute redirectTo={`${lang}/login`} path="changePassword">
              <ChangePassword />
            </PrivateRoute>
          }
        />
        
         <Route
          path='*'        
          element={<QuestionPublishResult/>}
          // element={<NotFound/>}
        />
      </Routes>     
    </Fragment>
    // </BrowserRouter>
  );
}

export default App;
