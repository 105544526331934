import http from "./http-common";

class TopicService { 
  async getAll() {       
   const respons= await http.get(`Topic/GetByLanguageId`);    
    return respons
  }  
  async getTopicTypeAll() {     
    const respons= await http.get(`Topic/GetTopicTypeByLanguageId`);    
     return respons
   }   
   async insert(newItem) {     
    const respons= await http.post(`Topic/Insert`,newItem);    
     return respons
   }       
}

export default new TopicService();