
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "./ResultRow.scss";
import "../../iconstyle.scss";
import MyEditor from "../MyEditor/MyEditor";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";

const ShowQuestionReply = ({ showQuestionAndReply, contentText, replyText, handleCloseQuestionAndReply }) => { 
    const [contentTextEditorLoaded, setContentTextEditor] = useState(false);
    const [replyTextEditorLoaded, setReplyTextEditor] = useState(false);
    useEffect(() => {
        setContentTextEditor(true);
        setReplyTextEditor(true);

    }, []);

    return <Modal
        show={showQuestionAndReply}
        onHide={handleCloseQuestionAndReply}
        size="lg"
        aria-labelledby=""
        className="searchoption-statuse-aria"
    >
        <Modal.Header closeButton className=" header-new-question-color">
            <Modal.Title>
               {useSelector(getTranslate("ShowQuestionReply_Expression_EditHeader"))}
               {/* متن کامل سوال وپرسش */}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-question-body-color">
            <div className="d-flex flex-grow-1 flex-column justify-content-center p-1  question-answer">
                <div className="d-flex">
                    <i className="icon icon-question p-2"></i>

                    <div
                        className={`d-flex flex-grow-1 text-right p-1 resultrow-replaytext `}
                    >

                        <MyEditor
                            name="ContentText"
                            editorLoaded={contentTextEditorLoaded}
                            value={contentText}
                            disabled={true} />

                    </div>
                </div>
                <div className="d-flex hr-line"></div>
                <div className="d-flex">
                    <i className="icon icon-answer p-2"></i>
                    <div
                        className={`d-flex flex-grow-1 text-right  p-1 resultrow-replaytext `}
                    >

                        <MyEditor
                            name="ReplyText"
                            editorLoaded={replyTextEditorLoaded}
                            value={replyText}
                            disabled={true} />
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>;
}
export default ShowQuestionReply;