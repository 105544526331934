import axios from 'axios'

import TokenHelper from '../_helpers/token.helper'
import { getLanguage } from '../_helpers/util'
import $ from 'jquery'
const getBaseUrl = () => {
  let lang = getLanguage()
  switch (process.env.NODE_ENV) {
    case 'production':
      return `https://question-api.motaghin.com/${lang}/`
    case 'development':
    default:
      return `https://localhost:44325/${lang}/`
  }
}
export function changeToken(token = null) {
  axiosInstance.defaults.headers['Token'] = token
}
export function changeLang(lang) {
  switch (process.env.NODE_ENV) {
    case 'production':
      axiosInstance.defaults.baseURL = `https://question-api.motaghin.com/${lang}/`
      break
    case 'development':
    default:
      axiosInstance.defaults.baseURL = `https://localhost:44325/${lang}/`
  }
}
let axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-type': 'application/json',
    Token: TokenHelper.getToken(),
  },
})

//اگر بخواهیم پاسخ را بررسی کنیم بطور عمومی
axiosInstance.interceptors.response.use(
  (response) => {
    //alert("jjjjj");
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.message === 'Network Error') {
      // var obj = $.dialog({
      //   title: "هشدار",
      //   content: "دسترسی به شبکه قطع می باشد",
      //   rtl: true,
      //   type: "red",
      //   animation: "top",
      // });
      // setTimeout(function () {
      //   obj.close();
      // }, 1000);
    }
    return Promise.reject(error)
  }
)

//بخواهیم چیزی به درخواست اضافه کنیم بطور عمومی
axiosInstance.interceptors.request.use(
  (request) => {
    //alert("jjjjj");
    return request
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axiosInstance
