import React, { useState } from "react";
import "./Login.scss";
import AccountService from "../../_services/account.service";
import TokenHelper from "../../_helpers/token.helper";
import { useNavigate } from "react-router-dom";
import { addAccount } from "./AccountSlice";
import { changeToken } from "../../_services/http-common";
import { useDispatch, useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import { getLanguage, showLoadingDialog } from "../../_helpers/util";
import { debug } from "webpack";

export default function Login() {
  const dispatch = useDispatch();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };
  const login = async () => {
    var loginMessage = showLoadingDialog();

    let lang = getLanguage();
    const user = { accountName: username, password: password };
    await AccountService.login(user)
      .then((res) => {
        if (res.status) {
          loginMessage.close();
          TokenHelper.setUserToken(res.data);
          console.log(res);
          if (
            res.data.token != "" &&
            res.data.token != null &&
            res.data.token != undefined
          ) {
            dispatch(
              addAccount({
                accountName: res.data.name,
                token: res.data.token,
                accountPermission: res.data.accountPermission,
              })
            );
            changeToken(res.data.token);
            navigate(`/`);
            //Callbacks();
          }
          debugger;
        } else {
          console.log("GGG");
        }
      })
      .catch((error) => {
        loginMessage.close();
        alert("نام کاربری و گذرواژه همخوانی ندارند");
        return;
      });
  };

  const handleKeyDown = (e) => {
    if (e.key == "Enter") login();
  };

  return (
    <div className="loginform">
      <div className="p-3 rounded-top headercolor"></div>
      <div className="border  p-3 rounded-bottom bordercolor">
        <div className="input-group mb-3">
          <input
            className="form-control"
            id="userName"
            placeholder={useSelector(getTranslate("Login_UserName"))}
            name="userName"
            onChange={(e) => setUserName(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              {useSelector(getTranslate("Login_UserName"))}
            </span>
          </div>
        </div>

        <div className="input-group mb-3" onKeyDown={handleKeyDown}>
          <input
            type="password"
            className="form-control"
            id="pwd"
            placeholder={useSelector(getTranslate("Login_Password"))}
            name="pswd"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              {useSelector(getTranslate("Login_Password"))}
            </span>
          </div>
        </div>

        {/* <div class="CheckBoxForLogin">
            <input type="checkbox" name="remember" value={"کاربر ادمین"} /> Remember me
        </div> */}
        <button onClick={handleSubmit} className="btn btn-primary">
          {useSelector(getTranslate("Login_Btn_logIn"))}
        </button>
        {/* <Link to="/register" className="p-3">ثبت نام</Link> */}
      </div>
    </div>
  );
}
