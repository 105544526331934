import { useSelector } from "react-redux"
import { getTranslate } from "../Phrase/PhraseSlice"
import OneSelect from "../Templates/OneSelect"
const oneSelectOptions = [
    { key: "8", value: "8" },
    { key: "40", value: "40" },
    { key: "72", value: "72" },
  ];

const QuestionsChangedHeader=(props)=>{
    return <div className="d-flex justify-content-between pb-2">
   
      <button type="button" className="search-button  align-items-center" onClick={props.onClickSaveChange}>تایید تغییرات</button>
      {/* <div className="d-md-flex flex-row flex-grow-0"> */}
      <div className="d-flex search-result-count-section align-items-center border">        
        <span className="pl-2 pr-2">
          {useSelector(getTranslate("SearchResult_Expression_ResultCount"))}
          {/* تعداد پاسخ  */}
        </span>
        <span className="d-flex flex-grow-1  pl-2 pr-2 search-result-count">
          {props.totalCount}
        </span>
      {/* </div> */}
    </div>
    <div className=" d-flex flex-grow-0  align-items-center">
        <label className="m-1">
          {useSelector(
            getTranslate("SearchResult_Expression_CountPerPage")
          )}
          {/* تعداد نمایش در صفحه{" "} */}
        </label>
        <OneSelect
          className="d-flex flex-grow-0 align-items-center count-per-page"
          id="countPerPage"
          options={oneSelectOptions}
          onChange={props.onChangeCountPerPage}
        ></OneSelect>
      </div>
   
   
    </div>
}

export default QuestionsChangedHeader


