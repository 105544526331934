import React, {useRef } from "react";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import OneSelect from "../Templates/OneSelect";
import './SearchForChanged.scss';
const ChangeTypeItem=[
{key:"all",value:"همه"},
{key:"edit",value:"ویرایش"},
{key:"new",value:"سوال جدید"},
{key:"subNew",value:"سوال فرعی جدید"},
{key:"split",value:"تفکیک"},
{key:"delete",value:"حذف"}
]
const SearchForChanged = (props) => { 
    const questionRef= useRef();
    const siteIdRef=useRef();
    const changeTypeRef=useRef();  

  const searchKeyDown = (e) => {
    if (e.key == "Enter")
     {     
    searchClick();
    }
  };
  const searchClick=()=>{    
    const siteId=siteIdRef.current.value
    if(siteId=='' || siteId==undefined)
    {    
    props.onSearchClick({contentText:questionRef.current.value,changeType:changeTypeRef.current.value});
    }
    else
    {     
      props.onSearchClick({contentText:questionRef.current.value,questionId:siteId,changeType:changeTypeRef.current.value});
    }
    
  } 
  return (
    <div className="d-md-flex pt-3">
      <div className="flex-grow-0 automationid  mb-3 pr-2" onKeyDown={(e) => searchKeyDown(e)}>
        <input ref={siteIdRef}
          type="number"
          className="form-control"
          id="automationId"          
          placeholder={useSelector(
            getTranslate("Search_IdInSiteOrId_Placeholder")
          )} 
          name="automationId"          
        />
      </div>

      <div className="flex-grow-0 input-group mb-3 pr-2 searchtype-question" onKeyDown={(e) => searchKeyDown(e)} >
        <input ref={questionRef}
          className="form-control "
          id="searchtype-question"
          placeholder={useSelector(getTranslate("Search_Question_Placeholder"))} //"قسمتی از پرسش / پاسخ / عنوان ..."
          name="searchtype-question"         
        />        
      </div>
      <div className="flex-grow-0 input-group mb-3 pr-2 search-changetype ">     
      <OneSelect id="changeType" changeTypeRef={changeTypeRef} options={ChangeTypeItem}  className="form-control"> </OneSelect>     
     </div>  
     <div className="flex-grow-0 input-group mb-3 pr-2 search-changetype ">   
     <button type="button"  className="search-button  align-items-center"  title={useSelector(getTranslate("Search_Btn_Search_Hint"))} onClick={searchClick}>
            {/* [<i className=" icon icon-search"></i>] */}
          جستجو
          </button>
          </div>
    
    </div>
  );
};

export default SearchForChanged;
