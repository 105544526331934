import http from "./http-common";

class AccountService {

  async login(user) {
   return await http.post("Account/Login", user);
     
    /* return fetch('http://localhost:8080/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json()) */
  }



  async register(account) {
    return await http.post(`Account/Register`, account);
  }


  async getAccountPermission()
  {   
    const result=await http.get(`Account/GetAccountPermission`); 
   
    return result
  }


  async changePassword(password)
  {   
    const result=await http.put(`Account/ChangePassword?password=${password}`);  
    return result
  }

}

export default new AccountService();