import React, { useEffect, useState } from "react";
import "./Separation.scss";
import ResultRow from "../Search/ResultRow";
import QuestionService from "../../_services/question.service";
import { Modal } from "react-bootstrap";
import AddNewQuestion from "../Question/AddNewQuestion";
import MyEditor from "../MyEditor/MyEditor";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import $ from "jquery";
const Separation = ({ currentItem, onCancelClick,onSaveCompletedSeparation }) => {
  // const [parentId, setParentId] = useState(currentItem.id);
  const [items, setItems] = useState([]);
  const [currentId, setCurrentId] = useState(-1);
  const [actionType, setActionType] = useState("");


  //نمایش ویرایش و اضافه کردن سوال
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  // const handleCloseAddQuestion = () => setShowAddQuestion(false);
  const handleAddSplitQuestion = () => {
    setCurrentId(currentItem.questionsArchiveId);
    setActionType("Split");
    setShowAddQuestion(true);
  };

  const [contentTextEditorLoaded, setContentTextEditor] = useState(false);
  const [replyTextEditorLoaded, setReplyTextEditor] = useState(false);



  const onClickSave = () => {   
    setShowAddQuestion(false);
    if (currentItem.questionsArchiveId !== undefined)
    QuestionService.getByParentId(currentItem.questionsArchiveId)
      .then((response) => response.data)
      .then((data) => {
        setItems(data);      
      })
      .catch((erroe)=>
      alert("خطا در واکشی زیرمجموعه"));
   /*  QuestionService.completedSeparation(currentItem.id)
      .then((response) => {
        if (response.status) {
          alert("اطلاعات ذخیره شد");
          onSaveCompletedSeparation();
        }
      })
      .catch()
      ; */
  };




  const onClickCancel = () => {
    onCancelClick(false);
  };




  const editClickHandler = (id) => {
    setCurrentId(id);
    setActionType("Edit");
    setShowAddQuestion(true);
  };




  useEffect(() => {
    setContentTextEditor(true);
    setReplyTextEditor(true);
    if (currentItem.questionsArchiveId !== undefined)
      QuestionService.getByParentId(currentItem.questionsArchiveId)
        .then((response) => response.data)
        .then((data) => {
          setItems(data);        
        })
        .catch((erroe)=>
        alert("خطا در واکشی زیرمجموعه"));
  }, []);

  const deleteQuestions = (id) => {
    QuestionService.delete([id]).then((response) => {
      if (response.status) {
        //searchClick();
        var newItems = items.filter((item) => item.questionsArchiveId !== id);
        setItems(newItems);
      }      
    });
  }

  const handleDeleteQuestion = (id) => {
    $.confirm({
      title: "هشدار",
      content:`از حذف سوال های ${id} مطمئن هستید؟\n توجه داشته باشید : این سوال ها از سایت اصلی حذف خواهند شد.` ,
      rtl: true,
      type: "red",
      animation: "top",
      buttons: {
        confirm: {
          text: "بله",
          action:()=> deleteQuestions(id),           
        },
        cancel: { text: "خیر" },
      },
    });    
  };

  
  return (
    <>
      <div className="d-flex flex-column  separation-section">
        <div className="d-flex separation-header">
          <div className="d-flex w-50 justify-content-center">{useSelector(getTranslate("Separation_Word_Question"))}</div>
          <div className="d-flex w-50 justify-content-center">{useSelector(getTranslate("Separation_Word_Answer"))}</div>
        </div>

        <div className="d-flex   separation-question-reaonly-section">
          <div className="d-flex w-50">
            <i className="icon icon-question p-2"></i>
            <div className="flex-grow-1 text-right">
              <MyEditor
                name="ContentText"
                editorLoaded={contentTextEditorLoaded}
                value={currentItem.contentText}
                disabled={true}
              />
            </div>
          </div>
          <div className="d-flex w-50">
            <i className="icon icon-answer p-2"></i>
            <div className="flex-grow-1 text-right separation-replyText-readonly">
              <MyEditor
                name="ReplyText"
                editorLoaded={replyTextEditorLoaded}
                value={currentItem.replyText}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="d-flex separation-question-input-section flex-row-reverse">
          {/*  <span
              className="icon icon-edit result-row-button-icon separation-icon-edit"
              onClick={handleSplitQuestion}
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span> */}

          <span
            className="icon icon-add-question result-row-button-icon searchresult-icon-add"
            onClick={handleAddSplitQuestion}
            title={useSelector(getTranslate("Separation_Btn_AddQuestion_Hint"))}
            //"افزودن پرسش و پاسخ"
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
        </div>
        <div className="d-flex flex-column ">
          {items !== undefined
            ? items.map((item, index) => (
                <ResultRow
                  item={item}
                  key={item.questionsArchiveId}
                  index={index}
                  splitActionHide={true}
                  chooseActionHide={true}
                  editClick={() => editClickHandler(item.questionsArchiveId)}
                  handleDeleteQuestion={() => handleDeleteQuestion(item.questionsArchiveId)}
                />
              ))
            : ""}
        </div>
        {/*  <div className="d-flex separation-question-input-section">
          <div className="d-flex w-50">
            <i className="icon icon-question separation-icon"></i>
            <div className="flex-grow-1 text-right">
              <textarea
                type="text"
                className=" w-100"
                rows="1"
                style={{ resize: "none" }}
                placeholder="پرسش را وارد کنید..."
              />
            </div>
          </div>
          <div className="d-flex w-50">
            <i className="icon icon-answer separation-icon"></i>
            <div className="flex-grow-1 text-right">
              <textarea
                type="text"
                className="w-100"
                rows="1"
                style={{ resize: "none" }}
                placeholder="پاسخ را وارد کنید"
              />
            </div>
            
          </div>
        </div>
 */}
        <div className="d-flex">
          <button className="separation-button p-10" onClick={onSaveCompletedSeparation}>
          {useSelector(getTranslate("Separation_Btn_SaveCompletedSeparation"))}
            {/* ذخیره نهایی */}
          </button>
          <button className="separation-button p-10" onClick={onClickCancel}>
          {useSelector(getTranslate("Separation_Btn_Cancel"))}
            {/* انصراف */}
          </button>
        </div>
      </div>
      <Modal
        show={showAddQuestion}
        onHide={()=>setShowAddQuestion(false)}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        className="addquestion-width"
      >
        <Modal.Header closeButton className=" header-new-question-color">
          <Modal.Title>
          {useSelector(actionType==="Split"?getTranslate("AddNewQuestion_Expression_AddHeader"):getTranslate("AddNewQuestion_Expression_EditHeader"))}
            {/* {actionType === "Split" ? "اضافه کردن سوال جدید" : "ویرایش سوال"} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-question-body-color">
          <AddNewQuestion
            id={currentId}
            actionType={actionType}
            onClickSave={onClickSave}
            onClickCancel={()=>setShowAddQuestion(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Separation;
