import React, { useState, useEffect } from "react";
import "./Navigation.scss";
import logo from "../../image/logo-sm-fa.png";
import { addRemoveItem, getLanguage, setLanguage } from "../../_helpers/util";
import TokenHelper from "../../_helpers/token.helper";
import { useDispatch, useSelector } from "react-redux";
import { getTranslate, setCurrentLang } from "../Phrase/PhraseSlice";
import { changeLang } from "../../_services/http-common";
import { getNotFoundPage } from "../NotFound/NotFoundSlice";
import tokenHelper from "../../_helpers/token.helper";
import { Link } from "react-router-dom";
const Navigation = () => {
  //const account = useSelector(getAccount);
  const pathname = window.location.pathname; //returns the current url minus the domain name
  const pathNameSplit = pathname.trim().split("/");

  const dispatch = useDispatch();
  const account = TokenHelper.getUserToken();
  var t = account?.accountPermission?.filter(
    (a) => a.pageName == "questionsChanged"
  );
  const [currentLanguage, setCurrentLanguage] = useState("fa");
  const notFoundPage = useSelector(getNotFoundPage);

  function changeLanguage(lang) {
    setLanguage(lang);
    setCurrentLanguage(lang);
    dispatch(setCurrentLang(lang));
    changeLang(lang);
    if (pathNameSplit[1] != lang) {
      const nextURL = window.location.href.replace(
        `/${pathNameSplit[1]}/`,
        `/${lang}/`
      );
      window.history.replaceState(null, "", nextURL);
    }
  }

  useEffect(() => {
    setCurrentLanguage(getLanguage());
  }, []);

  const logoff = () => {
    tokenHelper.removeItem("userToken");
  };
  return (
    <>
      <nav className="navbar navbar-expand-sm header d-flex  justify-content-between">
        <span className="logo-sm"></span>
        {addRemoveItem({
          item: (
            <ul className="navbar-nav mr-auto">
              <li className="nav-item dropdown ">
                <span
                  className=" nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {currentLanguage}
                </span>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <span
                    className="dropdown-item dropdown-menu_items"
                    onClick={() => changeLanguage("fa")}
                  >
                    فارسی
                  </span>

                  <span
                    className="dropdown-item dropdown-menu_items"
                    onClick={() => changeLanguage("ar")}
                  >
                    العربیة
                  </span>
                </div>
              </li>

              <li className="nav-item dropdown">
                {/* <a className=""id="" role="" data-toggle="" aria-haspopup="true" aria-expanded="false" href="\">
                { account!=undefined?account:"میهمان"}
            </a> */}
                <span
                  className="nav-link dropdown-toggle "
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {account != null && account.name != undefined
                    ? account.name
                    : "ناشناس"}
                </span>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link
                    className="dropdown-item"
                    to={`/${currentLanguage}/questionManager`}
                  >
                    {useSelector(getTranslate("Menu_List"))}
                    {/* لیست پرسش و پاسخ */}
                  </Link>

                  {addRemoveItem({
                    item: (
                      <a
                        className="dropdown-item"
                        href={`/${currentLanguage}/questionsChanged`}
                      >
                        {useSelector(getTranslate("Menu_AcceptChange"))}
                        {/* تایید تغییرات */}
                      </a>
                    ),
                    condition:
                      account == null || account == undefined
                        ? false
                        : account.accountPermission.filter(
                            (a) =>
                              a.pageName.toString().toUpperCase() ==
                              "questionsChanged".toUpperCase()
                          ).length > 0,
                  })}

                  {addRemoveItem({
                    item: (
                      <a
                        className="dropdown-item"
                        href={`/${currentLanguage}/register`}
                      >
                        {useSelector(getTranslate("Menu_Register"))}
                        {/* تعریف کاربر جدید */}
                      </a>
                    ),
                    condition:
                      account == null || account == undefined
                        ? false
                        : account.accountPermission.filter(
                            (a) => a.pageName == "Register"
                          ).length > 0,
                  })}

                  <Link
                    className="dropdown-item"
                    to={`/${currentLanguage}/changePassword`}
                  >
                    {useSelector(getTranslate("Menu_ChangePassword"))}
                    {/* تغییر گذرواژه */}
                  </Link>
                  <Link
                    className="dropdown-item"
                    onClick={logoff}
                    to={`/${currentLanguage}/login`}
                  >
                    {useSelector(getTranslate("Menu_LogOff"))}
                    {/* تغییر گذرواژه */}
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="" hidden>
                  سابقه جستجو
                </Link>
              </li>
            </ul>
          ),
          condition: !notFoundPage,
        })}
      </nav>
    </>
  );
};

// <Navbar collapseOnSelect expand="lg" className="header">
// {/* <Container> */}
// <span className="logo-sm">
//   <Navbar.Brand href="/" title="پرسش و پاسخ">
//     {/* <img src={logo} alt="" width={100} height={50} /> */}
//   </Navbar.Brand>
// </span>
// <Navbar.Toggle aria-controls="responsive-navbar-nav">
//   <span className="sr-only">Toggle navigation</span>
//   <span className="icon-bar" style={{ marginBottom: "4px" }}></span>
//   <span className="icon-bar" style={{ marginBottom: "4px" }}></span>
//   <span className="icon-bar"></span>
// </Navbar.Toggle>
// <Navbar.Collapse id="responsive-navbar-nav">
//   <Nav>
//     {/* <Nav.Link href="#features">Features</Nav.Link>
//     <Nav.Link href="#pricing">Pricing</Nav.Link> */}
//     <NavDropdown
//       title={
//         account.accountName != undefined
//           ? account.accountName
//           : "میهمان"
//       }
//       id="collasible-nav-dropdown"
//     >
//       {addRemoveItem({
//         item: (
//           <NavDropdown.Item href="/register">
//             تعریف کاربر جدید
//           </NavDropdown.Item>
//         ),
//         condition:
//           account == undefined
//             ? false
//             : account.accountPermission.filter(
//                 (a) => a.pageName == "Register"
//               ).length > 0,
//       })}

//       <NavDropdown.Item href="/changePassword">
//         تغییر گذرواژه
//       </NavDropdown.Item>
//       {/* <NavDropdown.Divider />جدا کننده
//       <NavDropdown.Item href="#action/3.4">
//         Separated link
//       </NavDropdown.Item> */}
//     </NavDropdown>

//   </Nav>
//   <Nav>
//     <Nav.Link href="#deets" hidden>
//       سابقه جستجو
//     </Nav.Link>
//     {/* <Nav.Link eventKey={2} href="#memes" className="border-right">
//       <i className="nav-link icon-logout"></i>
//     </Nav.Link> */}
//   </Nav>
// </Navbar.Collapse>

// {/* </Container> */}
// </Navbar>

export default Navigation;
