import React, { useState, useEffect, useRef } from "react";
import "./AddNewQuestion.scss";
import QuestionGroupsService from "../../_services/group.service";
import QuestionService from "../../_services/question.service";
import TextEditor from "./Templates/TextEditor";
import TextareaEditor from "./Templates/TextareaEditor";
import languageService from "../../_services/language.service";
import OneSelect from "../Templates/OneSelect";
import ReplyerService from "../../_services/replyer.service";
import RefrenceTypeService from "../../_services/refrenceType.service";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import padleft from "pad-left";
import MyEditor from "../MyEditor/MyEditor";
import { addRemoveItem, parseFarsiNumber, showDialog, showLoadingDialog } from "../../_helpers/util";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { getCurrentLang, getTranslate } from "../Phrase/PhraseSlice";


import QuestionTopic from "./QuestionTopic/QuestionTopic";

function AddNewQuestion(props) {
  const currentLang = useSelector(getCurrentLang); 

  const isScreenLargsize = useMediaQuery({ query: "(min-width: 992px)" }); //flex-md

  

  const [contentTextEditorLoaded, setContentTextEditor] = useState(false);
  const [replyTextEditorLoaded, setReplyTextEditor] = useState(false);
  const currentDate = new Date().toLocaleDateString("fa-IR").split("/");

  //دیتای پایه
  const [languages, setLanguages] = useState([]);
  const [groups, setGroups] = useState([]);
  const [replyers, setReplyers] = useState([]);
  const [refrenceTypeList, setRefrenceTypeList] = useState([]);

  //
  const [selectedGroupId, setSelectedGroupId] = useState("0");
  const [questionId, setQuestionId] = useState("--");
  const [replyDate, setReplyDate] = useState({
    year: parseFarsiNumber(currentDate[0]),
    month: parseFarsiNumber(currentDate[1]),
    day: parseFarsiNumber(currentDate[2]),
  });
  const [title, setTitle] = useState("");
  const [contentText, setContentText] = useState("");
  const [replyText, setReplyText] = useState("");
  const [replyQuestionImages, setReplyQuestionImages] = useState([]);

  const [selectedLanguageId, setSelectedLanguageId] = useState(currentLang);
  const [hasTranslate, setHasTranslate] = useState(false);
  const [selectedReplyerId, setSelectedReplyerId] = useState("0");
  const [selectedRefrenceTypeId, setSelectedRefrenceTypeId] = useState("0");
  const [refrence, setRefrence] = useState("");
  const [seo, setSeo] = useState(false);
  const [link, setLink] = useState(false);
  const [privateQuestion, setPrivateQuestion] = useState(false);
  const [matchWebSite, setMatchWebSite] = useState(false);
  const [publish, setPublish] = useState(false);
  const [split, setSplit] = useState(false);
  const [seperated, setSeperated] = useState(false);
  const [edit, setEdit] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [mainSelectedLanguageId, setMainSelectedLanguageId] = useState("0");
  const [translatedFromId, setTranslatedFromId] = useState("");
  const [previousImagePath, setPreviousImagePath] = useState([]);
  const [previousImageValue, setPreviousImageValue] = useState();
  const [isMatchWithWeb, setIsMatchWithWeb] = useState(false);
  const [publishedStatusClass, setPublishedStatusClass] = useState("");
  const [isBasicInformation, setIsBasicInformation] = useState(true);
  const refUploadFile = useRef();
  //Error
  const [errors, setErrors] = useState({});
  //Translate

  const matchWebsiteHin = useSelector(
    getTranslate("ResultRow_MatchWebsite_Hint")
  );
  const notMatchWebsiteHint = useSelector(
    getTranslate("ResultRow_Not_MatchWebsite_Hint")
  );
  const notExistsInWebsiteHint = useSelector(
    getTranslate("ResultRow_NotExists_InWebsite_Hint")
  );
  
  const refquestion = useRef(null);
  const [maxWidth, setMaxWidth] = useState();

  const [newQuestionTopic, setNewQuestionTopic] = useState([]);
  const [deleteQuestionTopic, setDeleteQuestionTopic] = useState([]);

  // function handleScroll(e) {
  //   if (e.target.scrollTop < e.target.clientHeight / 3)
  //     SetstatusYoffset(e.target.scrollTop);
  // }

  //عملگر ===
  //این عملگر دو متغیر را از دو بعد نوع و مقدار با هم مقایسه می کند اگر نوع و مقدار متغیرها دو طرف عملگر یکسان باشد مقدار
  //true را بر می گرداند. مثال های زیر همه مقدار true می گیرند
  //عملگر ==

  //بر خلاف عملگر ===،
  // عملگر == صرفا مقادیر دو متغییر را با هم مقایسه کرده در صورتی که مقادیر برابر باشد مقدار
  //true بر می گرداند. در صورتی که دو متغیر از یک نوع نباشند ابتدا تبدیل نوع انجام می شود سپس مقایسه صورت می گیرد

  // const addToErrors = (name, val) => {
  //   setErrors((prev) => ({
  //     ...prev,
  //     [name]: val,
  //   }));
  // };


  const onClickSave = () => {
    if (Object.keys(errors).length !== 0) {
      showDialog("هشدار","لطفا موارد مشخص شده را وارد کنید","red",1000);
      return;
    }

    let formData = new FormData();
    if (props.actionType === "Edit" || props.actionType === "Split") {      
      if (previousImagePath.length > 0)
        previousImagePath.forEach((element) => {
          formData.append("QuestionImageInfos[]", element);
        });
    }
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    if (props.actionType === "Edit")
      formData.append("QuestionsArchiveId", props.id);

    formData.append("QuestionGroupId", selectedGroupId);

    formData.append(
      "ReplyDate",
      replyDate.year +
        "-" +
        padleft(replyDate.month, 2, "0") +
        "-" +
        padleft(replyDate.day, 2, "0")
    );
    formData.append("Title", title);
    formData.append("ContentText", contentText);
    formData.append("ReplyText", replyText);
    if (replyQuestionImages !== undefined && replyQuestionImages.length > 0) {
      for (var x = 0; x < replyQuestionImages.length; x++) {
        formData.append("UploadQuestionImage", replyQuestionImages[x]);
      }
    }

    formData.append("LanguageId", selectedLanguageId);
    formData.append("HasTranslate", hasTranslate);
    formData.append("ReplyerId", selectedReplyerId);
    formData.append(
      "RefrenceTypeId",
      selectedRefrenceTypeId === undefined ? 0 : selectedRefrenceTypeId
    );
    formData.append("Refrence", refrence);

    formData.append("IsSeo", seo);
    formData.append("NeedToSeparate", split);
    formData.append("IsEdited", edit);
    formData.append("HasLink", link);
    formData.append("IsFavorite", favorite);
    formData.append("IsSeperated", seperated);
    formData.append("IsPrivate", privateQuestion);
    formData.append("IsMatchWebSite", matchWebSite);
    formData.append("IsReadyToPublish", publish);
    formData.append("QuestionTopicDtoInsert", JSON.stringify(newQuestionTopic));
    formData.append("QuestionTopicDtoDelete", JSON.stringify(deleteQuestionTopic));
    if (hasTranslate == true) {
      if (mainSelectedLanguageId != undefined && mainSelectedLanguageId != 0)
        formData.append("MainLanguageId", mainSelectedLanguageId);

      if (translatedFromId != undefined && translatedFromId != 0)
        formData.append("TranslateFromId", translatedFromId);
    }

    var objSaveData = showLoadingDialog();

    try {
      if (props.actionType === "Edit") {
        QuestionService.edit(formData)
          .then((response) => {
            objSaveData.close();
            if (response.status) {
              props.onClickSave(response.data);
            } else {
              showDialog(response.data);
            }
          })
          .catch((erroe) => {
            objSaveData.close();
            showDialog("خطا در ذخیره دیتا");
          });
      } else if (props.actionType === "Add") {
        QuestionService.insert(formData)
          .then((response) => {
            objSaveData.close();
            if (response.status) {
              showDialog("","سوال جدید ذخیره شد","green",1000);            
              props.onClickSave(response.data);
            } 
            
            else {
              showDialog("",response.data,"red");
            }
          })
          .catch((error) => {
            objSaveData.close();
            let response = error.response;
            if (response.status != undefined && response.status == "500") {
              showDialog("", "سوال جدید ذخیره شد و ولی در ذخیره تصاویر مربوط به پرسش و پاسخ مشکلی پیش آمد لطفا از قسمت ویرایش برای ذخیره مجدد تصاویر استفاده کنید","red",5000);             
              props.onClickSave(response.data);
            }
          });
      } else if (props.actionType === "Split") {
        formData.append("ParentId", props.id);
        QuestionService.insert(formData)
          .then((response) => {
            objSaveData.close();
            if (response.status) {
              props.onClickSave(response.data);
            } else {
              showDialog("",response.data);
            }
          })
          .catch((error) => {
            objSaveData.close();            
            let response = error.response;
            if (response.status == undefined && response.status == "500") {
              showDialog("","سوال جدید ذخیره شد و ولی در ذخیره تصاویر مربوط به پرسش و پاسخ مشکلی پیش آمد لطفا از قسمت ویرایش برای ذخیره مجدد تصاویر استفاده کنید"
              ,"red",5000)
              props.onClickSave(response.data);
            }
          });
      }
    } catch {}
  };

  const fetchCurrentQuestion = async () => {    
    var objFetchCurrentQuestion = showLoadingDialog();
    await QuestionService.getById(props.id).then((response) => {
      if (response.status) fillCurrentQuestion(response.data);
      else{
        objFetchCurrentQuestion.close();
       showDialog("","خطا در دریافت اطلاعات پرسش و پاسخ جاری");   
       props.onClickCancel();    
      }
    }).catch(()=>{
      objFetchCurrentQuestion.close();
      showDialog("","خطا در دریافت اطلاعات پرسش و پاسخ جاری");
      props.onClickCancel(); 
    });

    await QuestionService.getAllAnswerQuestionImage(props.id)
      .then((response) => {
        if (response.status) {
          objFetchCurrentQuestion.close();
          setPreviousImageValue(response.data);
        } else {
          objFetchCurrentQuestion.close();
          showDialog("","خطا در دریافت تصاویر مرتبط با پرسش و پاسخ جاری"); 
          props.onClickCancel();         
        }
      })
      .catch((erroe) => {
        objFetchCurrentQuestion.close();
          showDialog("","خطا در دریافت تصاویر مرتبط با پرسش و پاسخ جاری");
          props.onClickCancel();   
      });
  };

  // React
  // فقط یکبار در زمان
  //mount
  //کامپوننت آن را اجرا کند.
  const addNewQuestionExpressionNotSelected = useSelector(
    getTranslate("AddNewQuestion_Expression_NotSelected")
  );
  useEffect(() => {
    const ac = new AbortController(); //برای رفع خطای زیر
    //Can't perform a React state update on an unmounted component.
    // This is a no-op, but it indicates a memory leak in your application.
    // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
    setMaxWidth(refquestion.current.offsetWidth);
    // window.addEventListener("scroll", (e) => handleScroll(e), true);

    setContentTextEditor(true);
    setReplyTextEditor(true);
    QuestionGroupsService.getAll()
      .then((response) => response.data)
      .then((data) => {
        setGroups([
          { key: 0, value: addNewQuestionExpressionNotSelected },
          ...data,
        ]);
      })
      .catch();

    languageService
      .getAll()
      .then((response) => response.data)
      .then((data) => {
        setLanguages([
          { key: 0, value: addNewQuestionExpressionNotSelected },
          ...data,
        ]);
      })
      .catch();

    ReplyerService.getAll()
      .then((response) => response.data)
      .then((data) => {
        setReplyers([
          { key: 0, value: addNewQuestionExpressionNotSelected },
          ...data,
        ]);
      })
      .catch();

    RefrenceTypeService.getAll()
      .then((response) => response.data)
      .then((data) => {
        setRefrenceTypeList([
          { key: 0, value: addNewQuestionExpressionNotSelected },
          ...data,
        ]);
      })
      .catch();

    if (props.actionType == "Add") {
      setSelectedRefrenceTypeId("1");
    }
    if (props.actionType === "Edit" || props.actionType === "Split") {
      fetchCurrentQuestion();
    }
    return () => {
      ac.abort();
    }; // Abort both fetches on unmount//=> window.removeEventListener("scroll", (e) => handleScroll(e),true);
    //validate();
  }, []);

  //پر کردن فیلد ها با اطلاعات سوال جاری
  const fillCurrentQuestion = (currentQuestion) => {
    setSelectedGroupId(currentQuestion.questionGroupId);
    if (
      currentQuestion.questionId !== null &&
      currentQuestion.questionId !== undefined
    )
      if (
        currentQuestion.questionId == undefined ||
        currentQuestion.questionId == null ||
        currentQuestion.questionId == 0
      )
        setQuestionId("--");
      else setQuestionId(currentQuestion.questionId);
   
    const replyDate = new Date(currentQuestion.replyDate)
      .toLocaleDateString("fa-IR")
      .split("/");

    setReplyDate({
      year: parseFarsiNumber(replyDate[0]),
      month: parseFarsiNumber(replyDate[1]),
      day: parseFarsiNumber(replyDate[2]),
    });

    setTitle(
      currentQuestion.title === null || currentQuestion.title === undefined
        ? ""
        : currentQuestion.title
    );
    setContentText(
      currentQuestion.contentText === null ||
        currentQuestion.contentText === undefined
        ? ""
        : currentQuestion.contentText
    );
    setReplyText(
      currentQuestion.replyText === null ||
        currentQuestion.replyText === undefined
        ? ""
        : currentQuestion.replyText
    );
    setSelectedLanguageId(currentQuestion.languageId);
    setHasTranslate(currentQuestion.hasTranslate);
    setSelectedReplyerId(currentQuestion.replyerId);
    setSelectedRefrenceTypeId(currentQuestion.refrenceTypeId);

    setRefrence(
      currentQuestion.refrence === null ||
        currentQuestion.refrence === undefined
        ? ""
        : currentQuestion.refrence
    );

    if (props.actionType === "Edit") {
      //وضعیت ها
      setFavorite(currentQuestion.isFavorite);
      setEdit(currentQuestion.isEdited);

      setSplit(currentQuestion.needToSeparate);

      setSeperated(currentQuestion.seperated);

      setPublish(currentQuestion.isReadyToPublish);

      setPrivateQuestion(currentQuestion.isPrivate);
      setLink(currentQuestion.hasLink);

      setSeo(currentQuestion.isSeo);

      setIsMatchWithWeb(currentQuestion.isMatchWithWeb);
    }

    setPreviousImagePath(currentQuestion.questionImageInfos);
    if (
      currentQuestion.mainLanguageId != null &&
      currentQuestion.mainLanguageId != undefined
    )
      setMainSelectedLanguageId(currentQuestion.mainLanguageId);
    if (
      currentQuestion.translateFromId != null &&
      currentQuestion.translateFromId != undefined
    )
      setTranslatedFromId(currentQuestion.translateFromId);
  };

  useEffect(() => {
    let newErrors = {};
    Object.assign(newErrors, errors);

    if (selectedGroupId == "0") {
      newErrors = { ...newErrors, groupId: "گروه برای سوال انتخاب نشده" };
    } else {
      delete newErrors["groupId"];
    }

    if (selectedLanguageId == "0")
      newErrors = { ...newErrors, languageId: "زبانی انتخاب نشده" };
    else delete newErrors["languageId"];

    if (selectedReplyerId == "0")
      newErrors = { ...newErrors, replyerId: "پاسخ دهنده انتخاب نشده" };
    else delete newErrors["replyerId"];

    if (title == "")
      newErrors = { ...newErrors, title: "عنوان سوال نباید خالی باشد" };
    else delete newErrors["title"];

    if (contentText == "")
      newErrors = { ...newErrors, contentText: "متن پرسش نباید خالی باشد" };
    else delete newErrors["contentText"];

    if (replyText == "")
      newErrors = { ...newErrors, replyText: "متن پاسخ نباید خالی باشد" };
    // addToErrors("replyText", "متن پاسخ نباید خالی باشد");
    else delete newErrors["replyText"];
    //removeFromErrors("replyText");

    if (hasTranslate) {
      if (
        translatedFromId == "0" ||
        translatedFromId == undefined ||
        translatedFromId == ""
      )
        newErrors = {
          ...newErrors,
          translatedFromId: "کد ترجمه شده نباید خالی باشد",
        };
      else delete newErrors["translatedFromId"];

      if (mainSelectedLanguageId == "0" || mainSelectedLanguageId == undefined)
        newErrors = {
          ...newErrors,
          mainSelectedLanguageId: "زبان اصلی نباید خالی باشد",
        };
      else delete newErrors["mainSelectedLanguageId"];
    } else {
      delete newErrors["mainSelectedLanguageId"];
      delete newErrors["translatedFromId"];
    }
    setErrors(newErrors);
  }, [
    selectedGroupId,
    selectedLanguageId,
    selectedReplyerId,
    title,
    contentText,
    replyText,
    hasTranslate,
    translatedFromId,
    mainSelectedLanguageId,
  ]);

  const getStatuseValue = (name) => {
    switch (name) {
      case "seo":
        return seo;
      case "link":
        return link;
      case "privateQuestion":
        return privateQuestion;
        // case "matchWebSite":
        //   return matchWebSite;
        case "publish":
        return publish;
      case "split":
        return split;
      case "edit":
        return edit;
      case "favorite":
        return favorite;
      default:
        return false;
    }
  };

  const createSelectItems = () => {
    let items = [];
    groups.map((item, index) => {
      items.push(
        <option value={item.key} data-tokens={item.value} key={item.key}>
          {item.value}
        </option>
      );
    });
    return items;
  };
  const statusList = [
    {
      lable: useSelector(getTranslate("AddNewQuestion_StatusOption_Seo")),
      name: "seo",
    },
    {
      lable: useSelector(getTranslate("AddNewQuestion_StatusOption_Link")),
      name: "link",
    },
    {
      lable: useSelector(
        getTranslate("AddNewQuestion_StatusOption_PrivateQuestion")
      ),
      name: "privateQuestion",
    },
    //{
    //   lable: useSelector(
    //     getTranslate("StatuseCondition_StatusOption_MatchWebSite")
    //   ),
    //   name: "matchWebSite",
    // },
    {
      lable: useSelector(getTranslate("AddNewQuestion_StatusOption_Split")),
      name: "split",
    },
    {
      lable: useSelector(getTranslate("AddNewQuestion_StatusOption_Edit")),
      name: "edit",
    },
    {
      lable: useSelector(getTranslate("AddNewQuestion_StatusOption_Favorite")),
      name: "favorite",
    },
    {
      lable: useSelector(getTranslate("AddNewQuestion_StatusOption_Publish")),
      name: "publish",
    },
  ];
  const createStatusItems = () => {
    let items = [];
    statusList.map((item, index) => {
      items.push(
        <React.Fragment key={index}>
          <div
            className="d-flex align-items-center p-3"
            style={{ minWidth: "194px" }}
          >
            <input
              name={item.name}
              type="checkbox"
              className="add-question-status-checkbox"
              id={`customCheck-${index}`}
              onChange={handleStatusChange}
              checked={getStatuseValue(item.name)}
            />
            <label className="mr-2"  htmlFor={`customCheck-${index}`}>
              {item.lable}
            </label>
          </div>
          {addRemoveItem({
            item: (
              <div
                className="d-flex add-question-hr-line" // hidden={index == statusList.length-1}
              ></div>
            ),
            condition: index !== statusList.length - 1,
          })}
        </React.Fragment>
      );
    });
    return items;
  };

  const handleStatusChange = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case "seo":
        return setSeo(e.target.checked);
      case "link":
        return setLink(e.target.checked);
      case "privateQuestion":
        return setPrivateQuestion(e.target.checked);
        case "matchWebSite":
          return setMatchWebSite(e.target.checked);
        case "publish":
        return setPublish(e.target.checked);
      case "split":
        return setSplit(e.target.checked);
        case "seperated":
          return setSeperated(e.target.checked);
        case "edit":
        return setEdit(e.target.checked);
      case "favorite":
        return setFavorite(e.target.checked);
    }
  };

  const loadFile = (e) => {
    // setUploadQuestionImage()
    let sizeErrorCount = 0;
    let imagefiles = [...replyQuestionImages];
    for (var x = 0; x < e.target.files.length; x++) {
      let file = e.target.files[x];

      if (file.size > 204800) {
        //برا ی بررسی سایز فایل
        sizeErrorCount = sizeErrorCount + 1;
        continue;
      }
      if (file.type == "image/jpg" || file.type == "image/jpeg")
        imagefiles.push(file);
    }
    setReplyQuestionImages(imagefiles);
    if (sizeErrorCount) {
      showDialog("هشدار","اندازه بعضی از فایل های بزرگتر از 200K می باشد","red",4000);      
    }
  };

  const deleteImage = (index) => {
    let imagefiles = [...replyQuestionImages];
    imagefiles.splice(index, 1);
    setReplyQuestionImages(imagefiles);
    if (imagefiles.length == 0) {
      refUploadFile.current.value = null;
    }
  };
  function deleteImageFromPreviousImage(index) {
    let imagefiles = [...previousImagePath];
    imagefiles.splice(index, 1);
    setPreviousImagePath(imagefiles);
  }
  //نمایش در سایز بزرگ هنوز انجام نگرفته
  function showImage(index) {}

  function getImage(image) {
    if (previousImageValue != undefined && previousImageValue.length != 0) {
      let file = previousImageValue.find((item) => item.fileName == image);
      if (file != undefined) return "data:image/jpg;base64," + file.content;
    }
  }
  //return
  function createImageSection(
    imageList,
    fshowImage,
    deletefunction,
    getImageFunction,
    className
  ) {
    return imageList.map((item, index) => (
      <div className={`border d-flex flex-column ${className}`} key={index}>
        <span>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => deletefunction(index)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <img width="100px" src={getImageFunction(item)} alt="" key={index} />
        </span>
        <button
          className="btn-show-image"
          onClick={() => fshowImage(index)}
          hidden
        >
          نمایش
        </button>
      </div>
    ));
  }
  useEffect(() => {
    if (questionId == "--") {
      setPublishedStatusClass("id-in-web_value--color-red");
    } else {
      if (isMatchWithWeb)
        setPublishedStatusClass("id-in-web_value--color-green");
      else setPublishedStatusClass("id-in-web_value--color-gray");
    }
  }, [isMatchWithWeb, questionId]);
  function getIdInSiteHintTranslate() {
    if (questionId == "--") return notExistsInWebsiteHint;
    if (isMatchWithWeb) return matchWebsiteHin;
    return notMatchWebsiteHint;
  }

  const changeQuestionTopicHanadler = (
    newQuestionTopic,
    deleteQuestionTopic
  ) => {
    console.log("newQuestionTopic", newQuestionTopic);
    setDeleteQuestionTopic([...deleteQuestionTopic]);

    setNewQuestionTopic([...newQuestionTopic]);
  };

  return (
    <div className="d-lg-flex ">
      <div className={`d-flex  ${isScreenLargsize ? "col-9" : "col-12"} `}>
        <div className="w-100">
          <div className="d-flex justify-content-between">
            <div className="d-flex ">
              <div
                className={`card-header card-header-add-question ${
                  isBasicInformation ? "selected-tab" : ""
                }`}
                onClick={() => setIsBasicInformation(true)}
              >
                {useSelector(
                  getTranslate("AddNewQuestion_Expression_BasicInformation")
                )}
              </div>
              <div
                className={`card-header card-header-add-question ${
                  !isBasicInformation ? "selected-tab" : ""
                }`}
                onClick={() => setIsBasicInformation(false)}
              >
                {useSelector(
                  getTranslate("AddNewQuestion_Expression_FurtherInformation")
                )}
              </div>
            </div>
            <div className="">
              <span
                className={`add-question-group-item id-in-web_value--border id-in-web_value--size id-in-web_value--color-white ${publishedStatusClass}`}
                title={
                  useSelector(
                    getTranslate("AddNewQuestion_Expression_IdInSite")
                  ) +
                  "-" +
                  getIdInSiteHintTranslate()
                }
              >
                {useSelector(
                  getTranslate("AddNewQuestion_Expression_IdInSite")
                ) +
                  " : " +
                  questionId}
              </span>
            </div>
          </div>
          <div className="card-body d-flex flex-column card-body-add-question">
            <div className={`collapse ${isBasicInformation ? "show" : ""}`}>
              <div className="form-group d-flex">
                <label htmlFor="group" className="add-new-question-label">
                  {useSelector(getTranslate("AddNewQuestion_Group_Label"))}
                </label>

                <div className="add-question-input-item">
                  <select
                    name="group"
                    className={` add-question-group-item add-question-select-item new-question-select selectpicker ${
                      errors["groupId"] !== undefined ? "error" : ""
                    }`}
                    data-live-search="true"
                    value={selectedGroupId}
                    onChange={(e) => {
                      setSelectedGroupId(e.target.value);
                    }}
                  >
                    {createSelectItems()}
                  </select>
                </div>
              </div>

              <div className="form-group d-flex">
                <label htmlFor="replyDate" className="add-new-question-label">
                  {useSelector(getTranslate("AddNewQuestion_ReplyDate_Label"))}
                  {/* تاریخ پاسخ */}
                </label>
                <div className="add-question-input-item">
                  <DatePicker
                    value={replyDate}
                    onChange={setReplyDate}
                    locale="fa"
                    className=""
                    name="replyDate"
                  />
                </div>
              </div>
              <TextEditor
                labelClass="add-new-question-label"
                id="Title"
                onChange={(e) => {
                  setTitle(e.target.value);
                  // e.target.value !== ""
                  //   ? removeFromErrors("title")
                  //   : addToErrors("title", "عنوان سوال وارد نشده است");
                }}
                value={title}
                inputClass={errors["title"] !== undefined ? "error" : ""}
              >
                {useSelector(getTranslate("AddNewQuestion_Title_Label"))}
                {/* عنوان پرسش */}
              </TextEditor>

              <div className={`form-group d-flex`}>
                <label htmlFor="ContentText" className="add-new-question-label">
                  {useSelector(
                    getTranslate("AddNewQuestion_ContentText_Label")
                  )}
                  {/* متن پرسش */}
                </label>
                <div
                  ref={refquestion}
                  style={{ maxWidth: `${maxWidth}px`, minWidth: "100px" }}
                  className={`add-question-input-item ${
                    errors["contentText"] !== undefined ? "error" : ""
                  }`}
                >
                  <MyEditor
                    name="ContentText"
                    onChange={(data) => {
                      setContentText(data);
                    }}
                    editorLoaded={contentTextEditorLoaded}
                    value={contentText}
                  />
                </div>
              </div>

              <div className={`form-group d-flex`}>
                <label htmlFor="ReplyText" className="add-new-question-label">
                  {useSelector(getTranslate("AddNewQuestion_ReplyText_Label"))}
                  {/* متن پاسخ */}
                </label>
                <div
                  style={{ maxWidth: `${maxWidth}px`, minWidth: "100px" }}
                  className={`add-question-input-item ${
                    errors["replyText"] !== undefined ? "error" : ""
                  }`}
                >
                  <MyEditor
                    name="ReplyText"
                    onChange={(data) => {
                      setReplyText(data);
                    }}
                    editorLoaded={replyTextEditorLoaded}
                    value={replyText}
                  />
                </div>
              </div>
              <div className="form-group d-flex">
                <label id="replyer" className="add-new-question-label">
                  {useSelector(getTranslate("AddNewQuestion_Replyer_Label"))}
                  {/* پاسخ دهنده{" "} */}
                </label>
                <div className="add-question-input-item">
                  <OneSelect
                    className={`add-question-select-item new-question-select ${
                      errors["replyerId"] !== undefined ? "error" : ""
                    }`}
                    id="replyer"
                    options={replyers}
                    onChange={(e) => {
                      setSelectedReplyerId(e.target.value);
                    }}
                    selectedValue={selectedReplyerId}
                  />
                </div>
              </div>
              <div className="form-group d-flex">
                <label className="add-new-question-label">
                  {useSelector(getTranslate("AddTopic_Topic_Label"))}
                </label>
                <div className="add-question-input-item">
                  <QuestionTopic
                    changeQuestionTopic={changeQuestionTopicHanadler}
                  ></QuestionTopic>
                </div>
              </div>
            </div>

            <div className={`collapse ${!isBasicInformation ? "show" : ""}`}>
              <div className="form-group d-flex">
                <label className="add-new-question-label" htmlFor="language">
                  {useSelector(getTranslate("AddNewQuestion_Language_Label"))}
                  {/* زبان{" "} */}
                </label>
                <div className="add-question-input-item">
                  <OneSelect
                    className={`add-question-select-item new-question-select ${
                      errors["languageId"] !== undefined ? "error" : ""
                    }`}
                    id="language"
                    options={languages}
                    onChange={(e) => {
                      setSelectedLanguageId(e.target.value);
                    }}
                    selectedValue={selectedLanguageId}
                  ></OneSelect>
                </div>
              </div>
              <div className="form-group d-flex">
                <label
                  htmlFor="hasTranslate"
                  className="add-new-question-label"
                >
                  {useSelector(getTranslate("AddNewQuestion_Translate_Label"))}
                  {/* ترجمه */}
                </label>

                <div className="d-flex  add-question-input-item align-items-center">
                  <div className="d-flex justi-content-center align-items-center">
                    <input
                      id="hasTranslate"
                      type="checkbox"
                      checked={hasTranslate}
                      onChange={(e) => {
                        setHasTranslate(e.target.checked);
                      }}
                      className="add-question-status-checkbox"
                    />
                    <label htmlFor="hasTranslate" className="mr-2">
                      {useSelector(getTranslate("AddNewQuestion_HasTranslate"))}
                      {/* ترجمه شده است */}
                    </label>
                  </div>
                  <div
                    className={`d-xl-flex justi-content-center align-items-center border rounded mr-3 p-1 w-100 ${
                      hasTranslate == false ? "readOnly-mainLanguage" : ""
                    }`}
                  >
                    <div className="d-flex align-items-center mr-2 p-2">
                      <label
                        htmlFor="mainLanguage"
                        className="main-language  pr-2"
                      >
                        {useSelector(
                          getTranslate("AddNewQuestion_MainLanguage_Label")
                        )}
                        {/* زبان اصلی{" "} */}
                      </label>
                      <OneSelect
                        className={`add-question-select-item new-question-select ${
                          errors["mainSelectedLanguageId"] !== undefined
                            ? "error"
                            : ""
                        }`}
                        id="mainLanguage"
                        options={languages}
                        onChange={(e) => {
                          setMainSelectedLanguageId(e.target.value);
                        }}
                        selectedValue={mainSelectedLanguageId}
                      ></OneSelect>
                    </div>
                    <div className="d-flex mr-3">
                      <input
                        type="number"
                        className={`add-question-input-item form-control add-question__translate-id${
                          errors["translatedFromId"] !== undefined
                            ? "error"
                            : ""
                        }`}
                        id="id"
                        placeholder={useSelector(
                          getTranslate("AddNewQuestion_Id_Placeholder")
                        )} //"کداتوماسیون"
                        name="id"
                        onChange={(e) => setTranslatedFromId(e.target.value)}
                        defaultValue={translatedFromId}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group d-flex">
                <label className="add-new-question-label" htmlFor="sourcearea">
                  {useSelector(getTranslate("AddNewQuestion_SourceArea_Label"))}
                  {/* منبع{" "} */}
                </label>
                <div className="d-flex add-question-input-item" id="sourcearea">
                  <div className="d-flex flex-column w-100">
                    <div className="form-group  d-flex ">
                      <label
                        htmlFor="addquestionsourceid"
                        className="add-new-question-label"
                      >
                        {useSelector(
                          getTranslate("AddNewQuestion_SourceArea_Label")
                        )}
                        {/* منبع{" "} */}
                      </label>
                      <OneSelect
                        className={`add-question-select-item new-question-select ${
                          errors["refrenceTypeId"] !== undefined ? "error" : ""
                        }`}
                        id="addquestionsourceid"
                        options={refrenceTypeList}
                        onChange={(e) => {
                          setSelectedRefrenceTypeId(e.target.value);
                          // e.target.value !== "0"
                          //   ? removeFromErrors("refrenceTypeId")
                          //   : addToErrors(
                          //       "refrenceTypeId",
                          //       "لطفا نوع منبع را انتخاب کنبد"
                          //     );
                        }}
                        selectedValue={selectedRefrenceTypeId}
                      />
                    </div>
                    <div className="form-group d-flex w-100">
                      <label
                        htmlFor="addquestionsource "
                        className="add-new-question-label"
                      >
                        {useSelector(
                          getTranslate("AddNewQuestion_SourceText_Label")
                        )}
                        {/* متن منبع */}
                      </label>

                      <TextareaEditor
                        labelHidden={true}
                        labelClass="add-new-question-label"
                        classNameRoot="w-100"
                        type="text"
                        onChange={(e) => setRefrence(e.target.value)}
                        value={refrence}
                        className="form-control w-100"
                        rows="2"
                        id="addquestionsource"
                      ></TextareaEditor>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group d-flex ">
                <label htmlFor="uplodFile" className=" add-new-question-label">
                  {useSelector(
                    getTranslate("AddNewQuestion_ChoosePicture_Label")
                  )}
                  {/* تصویر پرسش/پاسخ */}
                </label>

                <div className="add-question-input-item">
                  <label htmlFor="uplodFile" className="uplod-file">
                    {useSelector(
                      getTranslate("AddNewQuestion_Btn_ChoosePicture")
                    )}
                    {/* انتخاب تصویر */}
                  </label>
                  <input
                    ref={refUploadFile}
                    id="uplodFile"
                    type="file"
                    // accept="image/*"
                    accept="jpg, .jpeg"
                    onChange={loadFile}
                    //accept={".jpg"}
                    multiple
                    // value={5}
                    hidden
                  />

                  <div className="gallery">
                    {createImageSection(
                      previousImagePath,
                      showImage,
                      deleteImageFromPreviousImage,
                      getImage,
                      ""
                    )}
                    {createImageSection(
                      replyQuestionImages,
                      showImage,
                      deleteImage,
                      URL.createObjectURL,
                      "addNewquestion-new-image"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`d-flex  ${isScreenLargsize ? "col-3" : "col-12"}`}
        // style={{
        //   top: `${isScreenLargsize ? statusYoffset + "px" : ""}`,
        //   transition: "top 1s ",
        //   minWidth: "250px",
        // }}
      >
        <div className="d-flext w-100">
          <div className="card-header card-header-add-question">
            {useSelector(getTranslate("AddNewQuestion_Expression_Statuse"))}
          </div>
          <div className="card-body  flex-grow-0 card-body-add-question statuse-add-question">
            <div className="d-flex flex-column add-question-checkbox w-100">
              {createStatusItems()}
            </div>
          </div>
          <div className="btn-action">
            <div
              className="btn-action--size  p-3 add-question-corner text-center btn-edit-new-question"
              onClick={onClickSave}
            >
              {useSelector(getTranslate("AddNewQuestion_Btn_Save"))}
              {/* ذخیره */}
            </div>
            <div
              className="btn-action_concel--color-red p-3 btn-action_concel--border-corner text-center btn-action--size btn-action_concel--animation"
              onClick={props.onClickCancel}
            >
              {/* {useSelector(getTranslate("AddNewQuestion_Btn_Save"))} */}
              {"لغو"}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default AddNewQuestion;
