import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import App from './containers/App';
import store from './containers/store';
import { Provider } from 'react-redux';
//import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-bootstrap';
import 'jquery-confirm';
import 'jquery-confirm/dist/jquery-confirm.min.css'
import './index.scss';
import { BrowserRouter } from 'react-router-dom';





ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
     <BrowserRouter>
    <App />
    </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
