import React, { useState } from "react";

import "./Login.scss";
import AccountService from "../../_services/account.service"

import { 
  useNavigate
} from "react-router-dom";

import $ from "jquery";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import { getLanguage } from "../../_helpers/util";



export default function ChangePassword() {
 
  const [repeatPassword, setRepeatPassword] = useState();
  const [password, setPassword] = useState();
  let lang = getLanguage();

  const navigate = useNavigate();
  const changePasswordSuccessfull=useSelector(getTranslate("ChangePassword_Successfully_UpdatePassword_Content"))

  const handleSubmit = async e => {
    e.preventDefault();
    if(password!=repeatPassword)
    {
      
        window.confirm("گذرواژه و تکرار گذرواژه همخوانی ندارند");
        return;
    }
 
    const response = await AccountService.changePassword(password)    
   
    if (response.status)
    {  
      
    var sucessAlert= $.alert({
        title: '',
        content:changePasswordSuccessfull,
        rtl: true,
    });
    setTimeout(function () {
      sucessAlert.close();
    }, 1000);      
  //alert("گذرواژه با موفعیت بروز رسانی شد")
      navigate(`/${lang}`);
      //Callbacks();
    }
  }

  return (
    <div className="loginform" >
      <div className="p-3 rounded-top headercolor"></div>
      <div className="border  p-3 rounded-bottom bordercolor">
     
        <div className="input-group mb-3">
          <input type="password" className="form-control" id="pwd" placeholder={useSelector(getTranslate("ChangePassword_Password_Placeholder"))} name="pswd" onChange={e => setPassword(e.target.value)} />
          <div className="input-group-append">
            <span className="input-group-text">{useSelector(getTranslate("ChangePassword_Password_Label"))}</span>
          </div>
        </div>
        
        <div className="input-group mb-3">
          <input type="password" className="form-control" id="pwd" placeholder={useSelector(getTranslate("ChangePassword_RepeatPassword_Label"))} name="repeatpassword" onChange={e => setRepeatPassword(e.target.value)} />
          <div className="input-group-append">
            <span className="input-group-text">{useSelector(getTranslate("ChangePassword_RepeatPassword_Label"))}</span>
          </div>
        </div>
        {/*  <div className="form-group form-check">
          <label className="form-check-label">
            <input className="form-check-input" type="checkbox" name="remember" /> Remember me
          </label>
        </div> */}
        <button onClick={handleSubmit} className="btn btn-primary">{useSelector(getTranslate("ChangePassword_Btn_Save"))}</button>
        {/* <Link to="/register" className="p-3">ثبت نام</Link> */}
      </div>

    </div>
  );
}