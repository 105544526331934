import React from 'react';
export default function OneSelect(props) {    
    return (
        <select value={props.selectedValue} ref={props.changeTypeRef}
            className={`${props.className}  selectpicker`}
            data-live-search="true" onChange={e =>props.onChange && props.onChange(e)}
            id={props.id} placeholder={"انتخاب"}  >           
            {props.options.map((option,index) =>
                <option key={index} value={option.key} onChange={props.onChange}>{option.value} </option>
            )}  
                   
        </select>
    )
}