import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import topicService from "../../../_services/topic.service";
import { getTranslate } from "../../Phrase/PhraseSlice";
import SelectionTypeahead from "../Templates/SelectionTypeahead";

const QuestionTopicAdd=({onAddItem,onClickShowTopicAdd,newTopic})=>{

    const [allTopic, setAllTopic] = useState([]);
    if(newTopic===undefined && newTopic.legth>0)
    setAllTopic(perv=>{return [...perv,newTopic]});
    useEffect(()=>{topicService.getAll().then((response) => {
        if (response.status) {
          setAllTopic(response.data);
        } else {
        }
      });},[])
    return <div className="form-group">
    <div className="input-group mb-3">
      <SelectionTypeahead
        data={allTopic}
        labelKey={"titleAndType"}
        onAddItem={onAddItem}
        multiple={false}
        label={useSelector(getTranslate("QuestionTopic_Placeholder_ChooseTopic"))}//{"موضوعات"}
        aria-describedby="basic-topic"       
      />
      <div
        className="input-group-prepend questionTopic_action_button questionTopic_add-new"
        // data-toggle="collapse"
        // href="#topicAdd"
        id="basic-topic"
        onClick={onClickShowTopicAdd}
      >
        +
      </div>
    </div>
  </div>
}
export default QuestionTopicAdd