import React, { useEffect, useState } from "react";
import questionService from "../../../_services/question.service";
import QuestionTopicList from "./QuestionTopicList";
import "./QuestionTopic.scss";
import AddTopic from "../../Topic/AddTopic";
import QuestionTopicAdd from "./QuestionTopicAdd";
import { useSelector } from "react-redux";
import { getTranslate } from "../../Phrase/PhraseSlice";
import { showDialog } from "../../../_helpers/util";

const QuestionTopic = ( props
  // questionsArchiveId,
  // handleCloseQuestionTopic,
  // showButton,
  // setDeleteQuestionTopic,
  // setNewQuestionTopic
) => {
  
  const [previousQuestionTopic, setPreviousQuestionTopic] = useState([]);
  const [newQuestionTopic, setNewQuestionTopic] = useState([]);
  const [deleteQuestionTopic, setDeleteQuestionTopic] = useState([]);
  const[showTopicAdd,setShowTopicAdd]=useState(false);
  const [newTopic, setNewTopic] = useState([]);
  const saveTranslate=useSelector(getTranslate("btn_Save"));
  const cancelTranslate=useSelector(getTranslate("btn_Cancel"));

  useEffect(() => {    
if(props.questionsArchiveId!==undefined)
    questionService.getTopListById(props.questionsArchiveId).then((response) => {
      if (response.status) setPreviousQuestionTopic(response.data);
    });
  }, []);

  const deleteHandler = (index) => {
    let questionTopic = [...previousQuestionTopic];
    let deleteItem = questionTopic.splice(index, 1);
    let indexInNew = newQuestionTopic.findIndex(
      (a) =>
        a.topicType == deleteItem[0].topicType && a.topicId == deleteItem[0].topicId
    );
    if (indexInNew > -1) {
      let newTopic = [...newQuestionTopic];
      newTopic.splice(index, 1);
      setNewQuestionTopic(newTopic);
    } else
      setDeleteQuestionTopic((previous) => {
        return [...previous, ...deleteItem];
      });
    setPreviousQuestionTopic(questionTopic);    
  };

  const addItemHandler = (item) => {
    if (item === undefined || item.lenght === 0) return;
    var index = previousQuestionTopic.findIndex(
      (q) => q.topicId === item[0].topicId && q.topicType === item[0].topicType
    );
    if (index > -1) return;

    setNewQuestionTopic((previous) => {
      return [...previous, ...item];
    });

    setPreviousQuestionTopic((previous) => {
      return [...previous, ...item];
    });

    let indeInDelete = deleteQuestionTopic.findIndex(
      (a) => a.topicType == item[0].topicType && a.topicId == item[0].topicId
    );
    if (indeInDelete > -1) {
      let deleteTopic = [...deleteQuestionTopic];
      deleteTopic.splice(indeInDelete, 1);
      setDeleteQuestionTopic(deleteTopic);
    }
    
  };
  const saveHandler = () => {
    questionService
      .insertDeletTopic(
        props.questionsArchiveId,
        newQuestionTopic,
        deleteQuestionTopic
      )
      .then((respose) => {
        if (respose.status) {
            showDialog("", "تغییرات  ذخیره شد","green",1000);
          props.handleCloseQuestionTopic();
        }
      });
  };
  const addTopicHandler=(item)=>{
    setNewTopic(item);
    setNewQuestionTopic(prev=>{return [...prev,item]});
    setPreviousQuestionTopic((prev) => {
      return [...prev, item];
    });
    setShowTopicAdd(!showTopicAdd)    
  }
  useEffect(()=>{
    if(props.changeQuestionTopic!==undefined)
    props.changeQuestionTopic(newQuestionTopic,deleteQuestionTopic)
  },[deleteQuestionTopic,newQuestionTopic])
  return (
    <>
      <div className="questionTopic">
      <QuestionTopicAdd onClickShowTopicAdd={()=>setShowTopicAdd(!showTopicAdd)} onAddItem={addItemHandler} newTopic={newTopic}/>
        <QuestionTopicList
          questionTopic={previousQuestionTopic}
          onDelete={deleteHandler}
          showDeleteButton={true}
        />
       {props.showButton && <div className="questionTopic_action ">
          <div
            className="questionTopic_action_button questionTopic_save"
            onClick={saveHandler}
          >
            {saveTranslate}
          </div>
          <div
            className="questionTopic_action_button questionTopic_cancel"
            onClick={props.handleCloseQuestionTopic}
          >
            {cancelTranslate}
          </div>
        </div>
}
      </div>
      <div className={`collapse ${showTopicAdd?"show":""}`} id="topicAdd">
        <hr></hr>
        <AddTopic onAddTopic={addTopicHandler} className="editor" />
      </div>
    </>
  );
};
export default QuestionTopic;
