import { createSlice } from '@reduxjs/toolkit'


export const NotFoundSlice = createSlice({
  name: 'NotFoundPage',
  initialState: { notFoundPage:false} ,
  reducers: {
    setNotFoundPage: (state, action) => {           
        state.notFoundPage =action.payload       
    },   
  },
})

export const { setNotFoundPage } = NotFoundSlice.actions

export const getNotFoundPage = (state) => state.notFoundPageReducer.notFoundPage

export default NotFoundSlice.reducer