import React, { useEffect, useState } from "react";
import questionService from "../../_services/question.service";
import Pagination from "../Pagination/Pagination";
import SearchForChanged from "../Search/SearchForChanged";
import QuestionInfo from "./QuestionInfo";
import QuestionPublishResult from "./QuestionPublishResult";
import "./QuestionsChanged.scss";
import QuestionsChangedHeader from "./QuestionsChangedHeader";
import $ from "jquery";
import { showLoadingDialog, showDialog } from "../../_helpers/util";
import { getTranslate } from "../Phrase/PhraseSlice";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

const QuestionsChanged = () => {
  const [questionList, setQuestionList] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(8);
  const [serachOption, setSerachOption] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [acceptResult, setAcceptResult] = useState(null);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    search();
  }, [serachOption]);

  const searchClickHandler = (serachParameter) => {
    setSerachOption((prev) => {
      return { ...serachParameter };
    });
  };
  const search = () => {
    var loading = showLoadingDialog();
    questionService
      .getChangedByCondition({
        ...serachOption,
        countPerPage: countPerPage,
        currentPage: currentPage,
      })
      .then((respons) => {
        if (respons.status) {
          setQuestionList(respons.data);
          if (respons.data.length > 0)
            setTotalCount(respons.data[0].questionAfter.totalCount);
          else setTotalCount(0);
        } else {
        }
        loading.close();
      })
      .catch(() => {
        loading.close();
      });
  };
  const handleAllChecked = (event) => {
    let checkboxes = document.getElementsByName("chooseAll");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = event.target.checked;
    }
  };
  const changeType = (question) => {
    let change = "ویرایش";
    if (question.questionId == 0 || question.questionId == undefined) {
      if (question.parentId == 0 || question.parrentId == undefined) {
        change = "سوال جدید";
      } else change = "سوال جدید فرعی";
    } else {
      if (question.isSeparated) change = "تفکیک";
      else if (question.isDelete) change = "حذف";
    }
    return change;
  };
  const onClickSaveChangeHandler = () => {
    let questionsArchiveIdList = [];
    var rows = $("#changeTable tr").filter(":has(:checkbox:checked)");
    if (rows.length > 0)
      for (let index = 0; index < rows.length; index++) {
        if (rows[index].id != "") questionsArchiveIdList.push(rows[index].id);
      }

    console.log("questionsArchiveIdList", questionsArchiveIdList);
    console.log("selectedItems", selectedItems);
    //return
    // if (questionsArchiveIdList.length > 0) {
    //   questionService.publish(questionsArchiveIdList).then((respons) => {
    if (selectedItems.length > 0) {
      questionService
        .publish(selectedItems)
        .then((respons) => {
          setAcceptResult(respons);
          setShowResult(true);
          search();
        })
        .catch((xhr) => {
          console.log(xhr);
          setAcceptResult([]); //
          setShowResult(true);
        });
    } else {
      showDialog("هشدار", "هیچ موردی انتخاب نشده است", "blue");
    }
  };

  const closeResultPreview = () => {
    setAcceptResult(null);
    search();
  };
  return (
    <>
      <div className="container-fluid question-changed">
        <SearchForChanged onSearchClick={searchClickHandler} />
        <QuestionsChangedHeader
          onChangeCountPerPage={(e) => {
            setCountPerPage(e.target.value);
            setSerachOption((prev) => {
              return { ...prev, countPerPage: e.target.value };
            });
          }}
          totalCount={totalCount}
          onClickSaveChange={onClickSaveChangeHandler}
        />

        <div className="table-responsive">
          <table
            id="changeTable"
            className="table table-bordered table-striped"
          >
            <thead className="headercolor">
              <tr>
                <th classs="a" scope="col">
                  ID
                </th>
                <th classs="a" scope="col">
                  سوال قبل از تغییر
                </th>
                <th classs="a" scope="col">
                  سوال بعد از تغییر
                </th>
                <th classs="a" scope="col" nowrap="true">
                  {" "}
                  نوع تغییرات{" "}
                </th>
                <th classs="a" scope="col">
                  <input
                    className="result-row-check"
                    type="checkbox"
                    value="false"
                    onChange={handleAllChecked}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {questionList.map((item) => (
                <tr
                  key={item.questionAfter.questionsArchiveId}
                  id={item.questionAfter.questionsArchiveId}
                >
                  <td>
                    {item.questionAfter.questionsArchiveId}/
                    {item.questionAfter.questionId}
                  </td>
                  <td>
                    {item.questionBefore && (
                      <QuestionInfo questionInfo={item.questionBefore} />
                    )}
                  </td>

                  <td>
                    <QuestionInfo
                      questionInfo={item.questionAfter}
                      questionBefore={item.questionBefore}
                    />
                  </td>

                  <td>{changeType(item.questionAfter)}</td>
                  <td>
                    <input
                      className="result-row-check"
                      type="checkbox"
                      value="false"
                      name="chooseAll"
                      onChange={(value) => {
                        const rowId = item.questionAfter.questionsArchiveId;
                        if (value.target.checked) {
                          let newArray = [...selectedItems];
                          newArray.push(rowId);
                          console.log("after push", newArray);
                          setSelectedItems(newArray);
                        } else {
                          let newArray = [...selectedItems];
                          newArray = newArray.filter((x) => x !== rowId);
                          console.log("after pop", newArray);
                          setSelectedItems(newArray);
                        }
                      }}
                      // onChange={props.handleCheckedForDelete}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <QuestionsChangedHeader
          onChangeCountPerPage={(e) => {
            setCountPerPage(e.target.value);
            setSerachOption((prev) => {
              return { ...prev, countPerPage: e.target.value };
            });
          }}
          totalCount={totalCount}
          onClickSaveChange={onClickSaveChangeHandler}
        />
        <div className="d-flex flex-grow-1 justify-content-center align-items-center">
          <Pagination
            className="pagination-bar border"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={countPerPage}
            onPageChange={(page) => {
              setcurrentPage(page);
              setSerachOption((prev) => {
                return { ...prev, currentPage: page };
              });
            }}
          />
        </div>
        <Modal
          show={showResult}
          onHide={() => setShowResult(false)}
          size="md"
          aria-labelledby="example-modal-sizes-title-lg"
          className="addquestion-width"
        >
          <Modal.Header closeButton className=" header-new-question-color" >
            <Modal.Title>
              {/* {useSelector(actionType==="Split"?getTranslate("AddNewQuestion_Expression_AddHeader"):getTranslate("AddNewQuestion_Expression_EditHeader"))} */}
              {"نمایش نتایج آپلود"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-body-color">
            <QuestionPublishResult
              data={acceptResult}
              closeResultPreview={closeResultPreview}
            ></QuestionPublishResult>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default QuestionsChanged;
