import { Navigate,useNavigate } from 'react-router-dom';
import { useEffect } from "react"; 
import TokenHelper from '../../_helpers/token.helper';
// import { addAccount,getAccount } from "./AccountSlice";
// import {  useDispatch,useSelector } from "react-redux";
// import accountService from '../../_services/account.service';
import { getHasPermission, getLanguage } from '../../_helpers/util';

const PrivateRoute=({ children, redirectTo,path })=> {   
  const navigate = useNavigate(); 
  // let lang=getLanguage();
  // const currentURL = window.location.href // returns the absolute URL of a page
  // const pathname = window.location.pathname //returns the current url minus the domain name
  // const ppathNameSplit=pathname.split('/');  
  // if(ppathNameSplit[1]!='fa'&& ppathNameSplit!='ar')         
  //     navigate(window.location.origin+`/${lang}`+pathname);  
  
  const userToken = TokenHelper.getUserToken(); 
 
 useEffect(
   ()=>{
   if (userToken == null)             
   navigate(`/${redirectTo}`);              
    }
    
    );    
 
    let isAuthenticated = userToken!=null;

    if(!isAuthenticated)
    return <Navigate to={redirectTo} />

    if(userToken.accountPermission==undefined )
    {
      alert("دسترسی شما مشخص نیست لطفا مجددا وارد شوید");
      return <Navigate to={redirectTo} />
    }
      if(userToken.accountPermission.length==0)
      {
        alert(" دسترسی شما تعریف نشده است لطفا با مدیر مربوطه تماس بگیرید و مجددا وارد شوید")
        return  <Navigate to={redirectTo} />
      }
      

    let hasPermission=getHasPermission(path,userToken.accountPermission);

    
    
    return hasPermission ? children  : <Navigate to={redirectTo} />;
  }
export default PrivateRoute;