import { createSlice } from '@reduxjs/toolkit'


export const AccountSlice = createSlice({
  name: 'AccountRole',
  initialState: { accountName:"",token:undefined,accountPermission:[]} ,
  reducers: {
    addAccount: (state, action) => {     
        state.accountName=action.payload.accountName;
        state.token=action.payload.token;  
        state.accountPermission =action.payload.accountPermission       
    },   
  },
})

export const { addAccount } = AccountSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const removeCondition = (key) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const getAccount = (state) => state.accountSliceReducer
//export const selectConditionResultById = (state, action) => state.filter((item) => item.key.payload == action.payload)
export default AccountSlice.reducer