import React, { useEffect, useState } from "react";
import topicService from "../../_services/topic.service";
import OneSelect from "../Templates/OneSelect";
import { Form } from "react-bootstrap";
import classes from "./AddTopic.module.scss";
import historicalEventService from "../../_services/historicalEvent.service";
import SelectionTypeahead from "../Question/Templates/SelectionTypeahead";
import MyEditor from "../MyEditor/MyEditor";
import creatorService from "../../_services/creator.service";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import { showLoadingDialog } from "../../_helpers/util";
const AddTopic = (props) => {
  const [topicType, setTopicType] = useState([]);
  const [selectedTopicTypeId, setSelectedTopicTypeId] = useState();
  const [newTopic, setNewTopic] = useState("");
  const [similar, setSimilar] = useState("");
  const [isValidTopic, setIsValidTopic] = useState(true);
  const [description, setDescription] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [allEvent, setAllEvent] = useState([]);
  const [selectEvent, setSelectEvent] = useState([]);
  const [descriptionEditorLoaded, setDescriptionEditorLoaded] = useState(false);
  const [introductionEditorLoaded, setIntroductionEditorLoaded] =
    useState(false);
  const [selectedCreatorId, setSelectedCreatorId] = useState("");
  const [creators, setCreators] = useState([]);
  const creatorTranslation=useSelector(getTranslate("AddTopic_Creator_Label"));



  useEffect(() => {
    topicService.getTopicTypeAll().then((response) => {
      if (response.status) {
        setTopicType(response.data);
        setSelectedTopicTypeId(response.data[0].key);
      }
    });

    historicalEventService.getAll().then((response) => {
      if (response.status) {
        setAllEvent(response.data);
      }
    });
    creatorService.getAll().then((response) => {
      if (response.status) {
        setCreators(response.data);
      }
    });

    setDescriptionEditorLoaded(true);
    setIntroductionEditorLoaded(true);
  }, []);
  const saveHandler = () => {
    if (newTopic.trim().length === 0) {
      setIsValidTopic(false);
      return;
    }   
    var objSaveData =  showLoadingDialog();

    let insertTopic = {
      topicType: selectedTopicTypeId,
      topicTitle: newTopic,
      similars: similar,
      description: description,
      historicalEventDtos: selectEvent,
      introduction: introduction,
      creatorId: selectedCreatorId==""?undefined:selectedCreatorId,
    };

    topicService.insert(insertTopic).then((response) => {
      if (response.status) {
        objSaveData.close();
        if (props.onAddTopic != undefined) props.onAddTopic(response.data);
      }
    });
  };
  const onChangeTopicHandler = (event) => {
    if (event.target.value.trim().length === 0) {
      setIsValidTopic(false);
      return;
    }
    setNewTopic(event.target.value);
    setIsValidTopic(true);
  };
  const addItemHandler = (item) => {
    setSelectEvent((previous) => {
      return [...previous, ...item];
    });
  };

  return (
    <div
      className={`${classes["topic-add"]} ${props.className} ${!isValidTopic && classes.invalid} `}
    >
      <h6>{useSelector(getTranslate("AddTopic_Expression_NewTopic_Label"))}</h6>
      <div className="d-flex">
        <label htmlFor="topicType" className={classes["label"]}>
        {useSelector(getTranslate("AddTopic_TopicType_Label"))}
        </label>
        <OneSelect
          className={`form-control  ${classes["input"]}`}
          id="topicType"
          options={topicType}
          onChange={(e) => setSelectedTopicTypeId(e.target.value)}
          selectedValue={selectedTopicTypeId}
        ></OneSelect>
      </div>

      <div className="d-flex mt-3">
        <label
          className={`${classes["topic-add_title-label"]} ${classes["label"]}`}
        >
          {useSelector(getTranslate("AddTopic_Topic_Label"))}
        </label>
        <input
          className={`form-control ${classes["topic-add_title"]} ${classes["input"]}`}
          type="text"
          placeholder= {useSelector(getTranslate("AddTopic_Topic_Placeholder"))}
          onChange={onChangeTopicHandler}
        />
      </div>
      <div className="d-flex mt-3">
        <label className={classes.label}>{useSelector(getTranslate("AddTopic_Similar_Label"))}</label>
        <input
          className={`form-control ${classes.input}`}
          type="text"
          placeholder={useSelector(getTranslate("AddTopic_Similar_Placeholder"))}
          onChange={(e) => setSimilar(e.target.value)}
        />
      </div>
      <div className="d-flex flex-column mt-3">
        <label>{useSelector(getTranslate("AddTopic_Historical_Label"))}</label>
        <SelectionTypeahead
          data={allEvent}
          labelKey={"title"}
          onAddItem={addItemHandler}
          multiple={true}
          label={useSelector(getTranslate("AddTopic_Historical_Placeholder"))}
          className={classes.input}
        />
      </div>

      <div className="d-flex flex-column mt-3">
        <label>{useSelector(getTranslate("AddTopic_Description_Label"))}</label>
        <div className={classes.editor}>
          <MyEditor
            name="description"
            onChange={(data) => {
              setDescription(data);
            }}
            editorLoaded={descriptionEditorLoaded}
            value={description}
          />
        </div>
      </div>

      <div
        className={`d-flex flex-column mt-3 ${
          selectedTopicTypeId === "2" ? "" : classes["introduction_hidden"]
        }`}
      >
        <label>{useSelector(getTranslate("AddTopic_Introduction_Label"))}</label>
        <div className={classes.editor}>
          <MyEditor
            name="introduction"
            onChange={(data) => {
              setIntroduction(data);
            }}
            editorLoaded={introductionEditorLoaded}
            value={introduction}
          />
        </div>
      </div>
      {selectedTopicTypeId === "4" && (
        <div className="d-flex mt-3">
          <label htmlFor="creator" className={classes["label"]}>
          {creatorTranslation}
          </label>
          <OneSelect
            className={`form-control`}
            id="creator"
            options={creators}
            onChange={(e) => setSelectedCreatorId(e.target.value)}
            selectedValue={selectedCreatorId}
          ></OneSelect>
        </div>
      )}

      <Form.Group>
        <button type="button" className={classes["topicAdd_save"]} onClick={saveHandler}>
        {useSelector(getTranslate("btn_Save"))}
        </button>
      </Form.Group>
    </div>
  );
};
export default AddTopic;
