import { useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import './SelectionTypeahead.scss';
const SelectionTypeahead = (props) => {
    const [selections, setSelections] = useState([]);  
    
    const onChangeHandler=(item)=>{
      props.onAddItem(item);
      if(props.multiple)     
      setSelections(item);
    }
    return (          
          <Typeahead
            align="right"
            id="basic-typeahead-single"
            labelKey={props.labelKey}
            onChange={onChangeHandler}
            options={props.data}
            placeholder={props.label}   
            selected={selections}  
            multiple={props.multiple}  
            className={props.className}    
            ref={props.topicRef}
          />
            
    );
  };
export default SelectionTypeahead;