import React from "react"
export default function TextEditor(props) {   
    return (
        <div className="form-group d-flex w-100">
            <label htmlFor={props.id} className={`${props.labelClass}`}>{props.children}</label>
            <div className="add-question-input-item">
                <input type="text" onChange={props.onChange} value={props.value} required
                className={`form-control text-editor-border-radius ${props.inputClass}`}
                 name={props.id} id={props.id} placeholder={props.children}></input>
            </div>
        </div>
    )
}
