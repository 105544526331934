import $ from "jquery";
export const addRemoveItem = ({ item, condition }) => {
  if (condition) {
    return item;
  }
  return "";
};
export const regexReplace = (input, pattern, replace) => {
  //input='<p>بسم الله<br>حضرة العلامة آية الله السيد محمد محسن الطهراني أعزكم الله السلام عليكم ورحمة الله وبركاته<br>نود معرفة رأي سماحتكم عن مسألة التأديب أو الضرب للطفل لأجل تأديبه، ففي بعض الأحيان يصل الأمر مع الطفل إلى حالة هستيرية لا يعود يسمع ولا يقنع بشيء، ويصبح يركض ويرفض ويجاوب وقد يشتم ويسب بشكل جنوني.. ففي مثل هذه الحالات ما هو الحل؟<br>وهل يجوز تأديبه بالضرب؟<br>وإلى أي حد أي كم ضربة مثلا؟<br>وما هي طبيعة الآلة أو الوسيلة التي كان الأئمة يضربون بها؟<br>حفظكم الله ورعاكم</p>'
  let newStr = input.replace('"', "");
  newStr = newStr.replace(pattern, replace);
  newStr = newStr.replace(/<\s+>/g, " ");
  return newStr;
};
export const getHasPermission = (pageName, accountPermission) => {
  let hasPermission = false;

  if (pageName == "register") {
    if (accountPermission.filter((a) => a.pageName == "Register").length > 0)
      hasPermission = true;
  }

  if (pageName == "question")
    if (accountPermission.filter((a) => a.pageName == "Question").length > 0)
      hasPermission = true;

  if (pageName == "questionsChanged")
    if (accountPermission.filter((a) => a.pageName == "QuestionsChanged").length > 0)
      hasPermission = true;

  if (pageName == "changePassword") hasPermission = true;

  return hasPermission;
};
export function setLanguage(lang) {

  localStorage.setItem("language", lang);
}
export function getLanguage() {
  let language = localStorage.getItem("language");
  if (language == null) {
    language = "fa";
    setLanguage(language);
  }
  return language;
}
export function regPattern() {
  return /<\/[a-zA-Z]>|<[a-zA-Z]>|<br\/>|<br>/g;
}
export function showDialog(title,message,type="red",time=1000){
  var dialog = $.dialog({
    title: title,
    content: message,
    rtl: true,
    type: type,
    animation: "top",
  });
setTimeout(function () {
  dialog.close();  
}, time);
}

export function showLoadingDialog(title=""){
  return $.dialog({
    closeIcon: false,
    title: title,
    content:
      '<div class="save-loding d-flex justify-content-center  "> <div class="spinner-border text-info" role="status" style="width: 3rem; height: 3rem;" > <span class="sr-only">Loading...</span> </div></div>',
    rtl: true,
    type: "green",
    animation: "top",
  });
}
export function showConfirm(title,content,type,yasAction,noAction)
{
  $.confirm({
    title: title,
    content: content,
    rtl: true,
    type: type,
    animation: "top",
    buttons: {
      confirm: {
        text: "بله",
        action: yasAction,
      },
      cancel: { text: "خیر" ,action:noAction },
    },
  });
}
export const parseFarsiNumber = (str) => {
  var num=str;
  try{
    num= Number(
      num
        .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
          return d.charCodeAt(0) - 1632; // Convert Arabic numbers
        })
        .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
          return d.charCodeAt(0) - 1776; // Convert Persian numbers
        })
    );
  }
  catch(err){      
  }
  return num;
};
