import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './Home.css';
import TokenHelper from '../../_helpers/token.helper';
import Login from '../Account/Login';

import SearchResult from '../Search/SearchResult';


const home = (props) => {
     const userToken=TokenHelper.getUserToken();

return(
    // <div>
   
    // {!userToken ? (
    //  <Login setToken={userToken} />
     
    // ) : (
        <SearchResult/>
    // )}

//   </div>
)


  
}
export default home;