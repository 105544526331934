import React, { useEffect, useState, useRef } from "react";
import "./ResultRow.scss";
import "../../iconstyle.scss";
import Separation from "../Question/Separation";
import MyEditor from "../MyEditor/MyEditor";
import questionService from "../../_services/question.service";
import { addRemoveItem, regexReplace, showConfirm, showDialog } from "../../_helpers/util";

import ShowQuestionReply from "./ShowQuestionReply";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import QuestionTopic from "../Question/QuestionTopic/QuestionTopic";
import { Modal } from "react-bootstrap";

export default function ResultRow(props) {
  const [currentFont, setCurrentFont] = useState();
  const refContentText = useRef();
  const [contentTextDivWidth, setContentTextDivWidth] = useState(0);
  const [separationShow, setSeparationShow] = useState(false);
  const handleOnClickSplit = () => {
    if (separationShow) setSeparationShow(false);
    else setSeparationShow(true);
  };
  const saveCompleted=(id)=>{    
      questionService
        .completedSeparation(id)
        .then((response) => {
          if (response.status) {
            props.item.needToSeparate = false;
            props.item.isSeperated = true;
            setSeparationShow(false);
          } else alert(response.data);
        })
        .catch((error) => alert(error));  
  }
  const onSaveCompletedSeparation = (id) => {
    if (id === undefined) {
        showDialog("","خطا","red",1000);     
      return;
    }
    showConfirm("هشدار","سوال مادر كامل تفكيك شد؟","red",()=>saveCompleted(id),"")
    // $.confirm({
    //   title: "هشدار",
    //   content: "سوال مادر كامل تفكيك شد؟",
    //   rtl: true,
    //   type: "red",
    //   animation: "top",
    //   buttons: {
    //     confirm: {
    //       text: "بله",
    //       action: function () {
    //         questionService
    //           .completedSeparation(id)
    //           .then((response) => {
    //             if (response.status) {
    //               props.item.needToSeparate = false;
    //               props.item.isSeperated = true;
    //               setSeparationShow(false);
    //             } else alert(response.data);
    //           })
    //           .catch((error) => alert(error));
    //       },
    //     },
    //     cancel: { text: "خیر" },
    //   },
    // });
  };

  const [isShowQuestionAndReply, setIsShowQuestionAndReply] = useState(false);
  const handleCloseQuestionAndReply = () => setIsShowQuestionAndReply(false);

  const [contentTextEditorLoaded, setContentTextEditor] = useState(false);
  const [replyTextEditorLoaded, setReplyTextEditor] = useState(false);

  const [isShowQuestionTopic, setIsShowQuestionTopic] = useState(false);
  const handleCloseQuestionTopic = () => setIsShowQuestionTopic(false);

  const matchWebsiteHin = useSelector(
    getTranslate("ResultRow_MatchWebsite_Hint")
  );
  const notMatchWebsiteHint = useSelector(
    getTranslate("ResultRow_Not_MatchWebsite_Hint")
  );
  const notExistsInWebsiteHint = useSelector(
    getTranslate("ResultRow_NotExists_InWebsite_Hint")
  );
  const isSeperatedHint = useSelector(
    getTranslate("ResultRow_Is_Seperated_Hint")
  );
  const questionTopicExpressionHeader=useSelector(getTranslate("QuestionTopic_Expression_Header"));

  useEffect(() => {
    const ac = new AbortController(); //برای رفع خطای زیر
    //Can't perform a React state update on an unmounted component.
    // This is a no-op, but it indicates a memory leak in your application.
    // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function

    setContentTextEditor(true);
    setReplyTextEditor(true);
    window.addEventListener("resize", getContentTextDivWidth);
    return () => {
      window.removeEventListener("resize", getContentTextDivWidth);
      ac.abort();
    }; // Abort both fetches on unmount
  }, []);

  const getContentTextDivWidth = () => {
    setCurrentFont(getComputedStyle(refContentText.current).font);
    setContentTextDivWidth(
      refContentText.current ? refContentText.current.clientWidth : 0
    );
  };
  useEffect(() => {
    const ac = new AbortController();
    setCurrentFont(getComputedStyle(refContentText.current).font);
    setContentTextDivWidth(
      refContentText.current ? refContentText.current.clientWidth : 0
    );
    return () => ac.abort();
  }, [refContentText.current]);

  const splitContent = "{...}";

  // const regPattern = /<\/[a-zA-Z]>|<[a-zA-Z]>|<br\/>|<br>/g; // this regex, remove link (<a href=.....> ... </a>)
  const regPattern = /<\/[b-zA-Z]>|<[b-zA-Z]>|<br\/>|<br>/g; // Changed By Lajevardi in 06/05/1403

  function getTextWidth(text, font) {
    if (font == undefined) return;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font; //|| getComputedStyle(document.body).font;
    return context.measureText(text).width;
  }
  function getPublishedStatusClass() {
    if (
      props.item.questionId == 0 ||
      props.item.questionId == undefined ||
      (props.item.questionId == null) == "--"
    ) {
      return "id-in-web_value--color-red";
    } else {
      if (props.item.isSeparated) return "id-in-web_value--color-orange";
      else if (props.item.isMatchWithWeb) return "id-in-web_value--color-green";
      else return "id-in-web_value--color-gray";
    }
  }
  function getIdInSiteHintTranslate() {
    if (
      props.item.questionId == 0 ||
      props.item.questionId == undefined ||
      props.item.questionId == null
    )
      return notExistsInWebsiteHint;
    if (props.item.isSeparated) return isSeperatedHint;
    if (props.item.isMatchWithWeb) return matchWebsiteHin;
    return notMatchWebsiteHint;
  }
  return (
    // <div className="d-flex border-top border-danger border-bottom resultrow">
    <>
      <div className="d-flex border-top  border-bottom resultrow">
        {/* کد */}
        <div
          className={`d-flex flex-column justify-content-center align-items-center border-left code ${getPublishedStatusClass()}`}
        >
          <div className=" d-flex justify-content-center align-items-center border-bottom">
            <span
              title={useSelector(getTranslate("AddNewQuestion_Id_Placeholder"))}
            >
              {props.item.parentId == -1
                ? props.item.questionsArchiveId
                : props.item.questionsArchiveId + "\\" + props.item.parentId}
            </span>
          </div>
          {/* <div className="d-flex flex-grow-1 hr-line"></div> */}
          <div className="d-flex align-items-center">
            <span
              title={
                useSelector(
                  getTranslate("AddNewQuestion_Expression_IdInSite")
                ) +
                "-" +
                getIdInSiteHintTranslate()
              }
            >
              {props.item.questionId == 0 ||
              props.item.questionId == undefined ||
              props.item.questionId == null
                ? "--"
                : props.item.questionId}
            </span>
          </div>
        </div>

        {/* گروه */}
        <div className="d-flex align-items-center  text-truncate pr-3 border-left group">
          <span> {props.item.groupTitle}</span>
        </div>

        {/* عنوان */}
        <div className="d-flex align-items-center pr-3 border-left  title">
          <span>{props.item.title}</span>
        </div>

        {/* پرسش و پاسخ */}
        <div
          ref={refContentText}
          className="d-flex flex-grow-1 flex-column justify-content-center pr-1 border-left question-answer"
        >
          <div className="d-flex pt-2 pb-1">
            <i className="icon icon-question pr-2"></i>

            <div
              className={`d-flex flex-grow-1 text-right pr-1 resultrow-replaytext ${props.textTruncate} `}
            >
              {/* <span>{props.item.contentText}</span> */}
              <div style={{ overflow: "hidden" }}>
                <MyEditor
                  name="ContentText"
                  editorLoaded={contentTextEditorLoaded}
                  value={regexReplace(props.item.contentText, regPattern, " ")}
                  disabled={true}
                />
              </div>
              {
                addRemoveItem({
                  item: (
                    <span
                      className="showQuestionAndReplyButton"
                      onClick={() => setIsShowQuestionAndReply(true)}
                      title={useSelector(
                        getTranslate("ResultRow_Btn_ShowQuestionAndReply_Hint")
                      )} //"نمایش کامل متن پرسش و پاسخ"
                    >
                      {splitContent}
                    </span>
                  ),
                  condition:
                    getTextWidth(props.item.contentText, currentFont) >
                    contentTextDivWidth, //isOverflow(contentTextElementRef),
                }) //props.item.contentText.length+splitContent.length > contentTextDivWidth
              }
            </div>
          </div>
          <div className="d-flex hr-line"></div>
          <div className="d-flex pt-1 pb-2">
            <i className="icon icon-answer pr-2"></i>
            <div
              className={`d-flex flex-grow-1 text-right  pr-1 resultrow-replaytext ${props.textTruncate} `}
            >
              {/* <span className="replytext">{props.item.replyText}</span> */}
              <div style={{ overflow: "hidden" }}>
                <MyEditor
                  name="ReplyText"
                  editorLoaded={replyTextEditorLoaded}
                  value={regexReplace(props?.item?.replyText??'', regPattern, " ")}
                  disabled={true}
                />
              </div>
              {
                addRemoveItem({
                  item: (
                    <span
                      className="showQuestionAndReplyButton"
                      onClick={() => setIsShowQuestionAndReply(true)}
                      title={useSelector(
                        getTranslate("ResultRow_Btn_ShowQuestionAndReply_Hint")
                      )} //"نمایش کامل متن پرسش و پاسخ"
                    >
                      {splitContent}
                    </span>
                  ),
                  condition:
                    getTextWidth(props.item.replyText, currentFont) >
                    contentTextDivWidth, //isOverflow(replyTextElementRef),
                }) //props.item.replyText.length + splitContent.length > contentTextDivWidth,
              }
            </div>
          </div>
        </div>

        {/* وضعیت */}
        <div className="d-flex flex-grow-0 flex-column justify-content-center align-items-center border-left status">
          <div className="d-flex">
            <span
              className={`icon icon-favorite pr-2 result-row-icon  ${
                props.item.isFavorite
                  ? "result-row-icon-favorite-checked"
                  : "result-row-icon-favorite-unchecked"
              }`}
              title={
                useSelector(
                  props.item.isFavorite
                    ? getTranslate("ResultRow_FavoriteChecked_Hint")
                    : getTranslate("ResultRow_FavoriteUnChecked_Hint")
                ) //props.item.isFavorite ? "برگزیده شده" : "برگزیده نشده"
              }
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </span>
            <span
              className={`icon icon-edit pr-2 result-row-icon ${
                props.item.isEdited
                  ? "result-row-icon-edit-checked"
                  : "result-row-icon-edit-unchecked"
              }`}
              title={useSelector(
                props.item.isEdited
                  ? getTranslate("ResultRow_EditChecked_Hint")
                  : getTranslate("ResultRow_EditUnChecked_Hint")
              )} //` ${props.item.isEdited ? "ویرایش شده" : "ویرایش نشده"}`}
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span>
            <span
              className={`icon icon-link pr-2 result-row-icon ${
                props.item.hasLink
                  ? "result-row-icon-link-checked"
                  : "result-row-icon-link-unchecked"
              }`}
              title={useSelector(
                props.item.hasLink
                  ? getTranslate("ResultRow_LinkChecked_Hint")
                  : getTranslate("ResultRow_LinkUnChecked_Hint")
              )} //` ${props.item.hasLink ? "لینک دارد" : "لینک ندارد"}`}
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </span>
          </div>
          <div className="d-flex pt-2">
            <i
              className={`icon icon-split pr-2  result-row-icon ${
                props.item.needToSeparate
                  ? "result-row-icon-split-checked"
                  : "result-row-icon-split-unchecked"
              }`}
              title={
                useSelector(
                  props.item.needToSeparate
                    ? getTranslate("ResultRow_SplitUnChecked_Hint")
                    : getTranslate("ResultRow_SplitChecked_Hint")
                ) //` ${   props.item.needToSeparate ? "نیاز به تفکیک دارد"  : "نیاز به تفکیک ندارد" }`
              }
            ></i>
            <span
              className={`icon icon-publish pr-2 result-row-icon  ${
                props.item.isReadyToPublish
                  ? "result-row-icon-publish-checked"
                  : "result-row-icon-publish-unchecked"
              }`}
              title={useSelector(
                props.item.isReadyToPublish
                  ? getTranslate("ResultRow_PublishChecked_Hint")
                  : getTranslate("ResultRow_PublishUnChecked_Hint")
              )} //` ${props.item.isPublish ? "منتشر شده" : "منتشر نشده"}`}
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span>

            <span
              className={`icon icon-seo pr-2 result-row-icon  ${
                props.item.isSeo
                  ? "result-row-icon-seo-checked"
                  : "result-row-icon-seo-unchecked"
              }`}
              title={useSelector(
                props.item.isSeo
                  ? getTranslate("ResultRow_SeoChecked_Hint")
                  : getTranslate("ResultRow_SeoUnChecked_Hint")
              )} //` ${props.item.isSeo ? "سئو شده" : "سئو نشده"}`}
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
              <span className="path6"></span>
            </span>
          </div>
        </div>

        {/* عملیات */}
        <div className="d-flex  align-items-center justify-content-center action">
          <i
            className={`icon icon-automation-code pr-2  result-row-button-icon`}
            onClick={() => setIsShowQuestionTopic(true)}
          ></i>

          <span
            className="icon icon-edit pr-2 result-row-button-icon"
            onClick={props.editClick}
            title={useSelector(getTranslate("ResultRow_Btn_Edit_Hint"))} //"ویرایش کنید"
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
          <i
            className={`icon icon-split pr-2  result-row-button-icon`} // ${props.item.needToSeparate ? "" : "disable"}
            onClick={handleOnClickSplit}
            hidden={props.splitActionHide}
            title={useSelector(getTranslate("ResultRow_Btn_Split_Hint"))} //"تفکیک کنید"
          >
            {/* </div>props.handleOnClickSplit}> */}
          </i>
          <div
            className={`p-1 mr-2 div-result-row-chcek  justify-content-center align-items-center ${
              props.chooseActionHide === undefined ? "d-flex" : ""
            }`}
            hidden={props.chooseActionHide}
            title={useSelector(getTranslate("ResultRow_ChooseForDelete_Hint"))} //"انتخاب برای حذف"
          >
            <input
              className="result-row-check"
              type="checkbox"
              value="false"
              id={props.item.questionsArchiveId}
              onChange={props.handleCheckedForDelete}
            />
          </div>

          <span
            className="icon icon-btn-delete serchresult-icon-delete result-row-button-icon"
            onClick={props.handleDeleteQuestion}
            hidden={props.deleteActionHide}
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
        </div>
      </div>
      {separationShow && (
        <Separation
          /*  contentText={props.item.contentText}
        replyText={props.item.replyText}
        id={props.item.id} */
          currentItem={props.item}
          key={props.item.questionsArchiveId}
          onCancelClick={setSeparationShow}
          onSaveCompletedSeparation={() =>
            onSaveCompletedSeparation(props.item.questionsArchiveId)
          }
        />
      )}

      {isShowQuestionAndReply && (
        <ShowQuestionReply
          showQuestionAndReply={isShowQuestionAndReply}
          contentText={props.item.contentText}
          replyText={props.item.replyText}
          handleCloseQuestionAndReply={handleCloseQuestionAndReply}
        />
      )}
      {isShowQuestionTopic && (
        <Modal
          show={isShowQuestionTopic}
          onHide={handleCloseQuestionTopic}
          size="md"
          aria-labelledby=""
          className="searchoption-statuse-aria"
        >
          <Modal.Header closeButton className=" header-new-question-color">
            <Modal.Title>             
              {questionTopicExpressionHeader}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QuestionTopic             
              questionsArchiveId={props.item.questionsArchiveId}
              handleCloseQuestionTopic={handleCloseQuestionTopic}
              showButton={true}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
