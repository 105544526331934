import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PhraseService from "../../_services/phrase.service"

export const PhraseSlice = createSlice({
  name: 'PhraseTranslate',
  initialState: {
    translate: [], status: 'idle',
    error: null, langId: 1
  },
  reducers: {
    setCurrentLang: (state, action) => {
      switch (action.payload) {
        case 'fa':
          state.langId = 1;
          break;
        case 'ar':
          state.langId = 2;
          break;
        default:
          state.langId = 1;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTranslate.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchTranslate.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.translate = state.translate.concat(action.payload)
      })
      .addCase(fetchTranslate.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})
export const { setCurrentLang } = PhraseSlice.actions
export const fetchTranslate = createAsyncThunk('translate/fetchTranslate', async () => {
  const response = await PhraseService.getAll()
  return response.data
})  

export const selectAllTranslate = state => state.phraseSliceReducer.translate
export const getCurrentLang = state => state.phraseSliceReducer.langId

export const getTranslate = (action) => (state) => {
  if (state.phraseSliceReducer.translate == undefined)
    return undefined;
  if (state.phraseSliceReducer.translate.length == 0)
    return undefined;
  let item = state.phraseSliceReducer.translate.find((item) => item.languageId == state.phraseSliceReducer.langId && item.phraseTitle == action)

  return item.translate;
}

export default PhraseSlice.reducer