import React, { useEffect, useState, useRef } from "react";
import StatuseConditionComponent from "./StatusConditionComponent";
//import CloseIcon from '@bit/zenhomes.react-components.close-icon';
import './StatusCondition.scss';


import { useSelector, useDispatch } from 'react-redux';
import {
  removeCondition,
  saveCondition,
  selectConditionResult,
  resetCondition,
} from './StatusConditionSlice';
import { getTranslate } from "../Phrase/PhraseSlice";


const StatusCondition = (props) => { 
  const dispatch = useDispatch();  
  const historyConditionResult = useSelector(selectConditionResult);

  useEffect(() => {
    if (historyConditionResult.length == 0)
      addCondition();  
  }, []) 


  const handleRemoveCondition = (key) => {
    dispatch(removeCondition(key));  
  }

 
  const addCondition = () => {    
    let countertmp = 0;
    if (historyConditionResult.length > 0)
      countertmp = historyConditionResult[historyConditionResult.length - 1].key + 1;
    const currentItem = { key: countertmp, name: "seo", value: 'false', connect: (countertmp === 0 ? '' : 'or') };
    dispatch(saveCondition(currentItem));       
  }


  return (
    <div className="d-flex flex-column">
      {historyConditionResult.map((item, index) => (
        <StatuseConditionComponent connect={item.connect} name={item.name} value={item.value} key={item.key} 
          // label= {useSelector(getTranslate(item.name))}
          componentKey={item.key}
          handleRemoveCondition={() => handleRemoveCondition(item.key)}
          addCondition={() => addCondition()} />

      ))
      }

      <span className="form-group d-flex  flex-grow-0  mt-2 align-items-center justify-content-between" >      
        <span className="statuse-condition-accept-btn asdf" onClick={() => {props.onAccept()}}>
          {/* تایید */}
          {useSelector(getTranslate("StatuseCondition_Btn_Accept"))}
          </span> 
          <span className="statuse-condition-accept-btn" onClick={() => {         
          dispatch(resetCondition());
          props.onHide();
        }}>
          {/* انصراف */}
          {useSelector(getTranslate("StatuseCondition_Btn_Cancel"))}
          </span>
      </span>
    </div>
  );
}
// }

export default StatusCondition;

