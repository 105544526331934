import React, { useEffect, useRef} from "react";

function MyEditor({ onChange, editorLoaded, name, value, disabled }) {
  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const uiLanguage = "fa";
  const contentLanguage="fa";
  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
  }, []);

  return (
    <>
      {editorLoaded ? (
        <div>
          <CKEditor
            disabled={disabled}
            type=""
            name={name}
            editor={ClassicEditor}
            data={value}
            onChange={(event, editor) => {
              if (onChange != undefined) {
                const data = editor.getData();
                onChange(data);
              }
            }}
            config={{ language: { ui: uiLanguage, content: contentLanguage } }}
          />
        </div>
      ) : (
        <div>در حال بارگزاری ...</div>
      )}
    </>
  );
}

export default MyEditor;
