import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeConditionValue } from "./StatusConditionSlice";
import "./StatusConditionComponent.scss";
import { getTranslate } from "../Phrase/PhraseSlice";

function StatuseConditionComponent(props) {
  const dispatch = useDispatch();
  const [connect, setConnect] = useState(props.connect);
  const [statusOption, setStatusOption] = useState(props.name);
  const [statusOptionValue, setStatusOptionValue] = useState(props.value);
  const componentKey = props.componentKey;

  //Translate
   const statuseConditionExpression= useSelector(getTranslate("StatuseCondition_Expression"))
   const statuseConditionBtnAddConditionHint= useSelector(getTranslate("StatuseCondition_Btn_AddCondition_Hint"))
   const statuseConditionStatusOptionAnd=useSelector(getTranslate("StatuseCondition_StatusOption_And"))
   const statuseConditionStatusOptionOr=useSelector(getTranslate("StatuseCondition_StatusOption_Or"))
   const statuseConditionBtnDeleteConditionHint=useSelector(getTranslate("StatuseCondition_Btn_DeleteCondition_Hint"))


  const statusList = [
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_Seo")), name: "seo" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_Link")), name: "link" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_PrivateQuestion")), name: "privateQuestion" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_Publish")), name: "publish" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_Split")), name: "split" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_Edit")), name: "edit" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_Favorite")), name: "favorite" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_HasImage")), name: "image" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_MatchWebSite")), name: "MatchWebSite" },
    { lable: useSelector(getTranslate("StatuseCondition_StatusOption_Splited")), name: "seperated" },
  ];
  const createSelectItems = () => {
    let items = [];
    statusList.map((item, index) => {
      items.push(
        <option value={item.name} data-tokens={item.name} key={index}>
          {item.lable}
        </option>
      );
    });
    return items;
  };

  //  useEffect(() => {
  //    dispatch(changeConditionValue({ key: componentKey, value: { name: statusOption, value: statusOptionValue, connect: connect } }))
  //   }, [connect, statusOption, statusOptionValue])


  return (
    <div className="d-flex pb-2" key={props.componentKey}>
      {props.componentKey == 0 ? (
        <span className="status-option-connection">{statuseConditionExpression} : </span>
      ) : (
        <select
          value={connect}
          className="status-condition-status-option selectpicker  status-condition-connect"
          data-live-search="true"
          id="connect"
          onChange={(e) => {
            setConnect(e.target.value);
            dispatch(
              changeConditionValue({
                key: componentKey,
                property: "connect",
                value: e.target.value
              })
            );
          }}
        >
          <option key="0" value="and">
          {statuseConditionStatusOptionAnd}
            {/* و */}
          </option>
          <option key="1" value="or">
            {statuseConditionStatusOptionOr}
            {/* یا */}
          </option>
        </select>
      )}
      <select
        value={statusOption}
        className="status-condition-status-option selectpicker ml-2 mr-2 status-condition"
        data-live-search="true"
        id="statusoption"
        name="name"
        onChange={(e) => {
          setStatusOption(e.target.value);
          dispatch(
            changeConditionValue({
              key: componentKey,
              property: "name",
              value: e.target.value
            })
          );
        }}
      >
        {createSelectItems()}
      </select>
      <div className="d-flex ">
      <select
        value={statusOptionValue}
        className="status-condition-status-option selectpicker status-option-value"
        data-live-search="true"
        id="0"
        name="statusOptionValue"
        onChange={(e) => {
          setStatusOptionValue(e.target.value);
          dispatch(
            changeConditionValue({
              key: componentKey,
              property: "value",
              value: e.target.value,
            })
          );
        }}
      >
        <option key="0" value="false">
         {useSelector(getTranslate("Expression_False"))} {/* نیست */}
        </option>
        <option key="1" value="true">
        {useSelector(getTranslate("Expression_True"))} {/*است*/}
        </option>
      </select>
      {props.componentKey == 0 ? (
        <span
          className="icon  icon-add-question serchresult-icon-add"
          title={statuseConditionBtnAddConditionHint}
          onClick={() => props.addCondition()}
        >
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
        </span>
      ) : (
        <div title={statuseConditionBtnDeleteConditionHint}>
          {" "}
          <svg
            style={{
              width: "24",
              height: "24",
              fill: "none",
              stroke: "currentColor",
              strokeWidth: "2",
              color: "red",
            }}
            className="statuse-condition-close"
            onClick={() => props.handleRemoveCondition()}
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
      )}
      </div>
    </div>
  );
}

export default StatuseConditionComponent;
