import React from "react";
import "./QuestionTopicList.scss";

const QuestionTopicList = ({ questionTopic, onDelete,showDeleteButton }) => {
  const createTopicItem = () => {   
    return questionTopic.map((topic, index) => (
      <span className={`border d-flex  questionTopicList_item`} key={index}>
       { showDeleteButton &&<button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => onDelete(index)}
        >
          <span aria-hidden="true" className="questionTopicList_close">
            &times;
          </span>
        </button>}
        <span className="questionTopicList_title">{topic.titleAndType}</span>
      </span>
    ));
  };

  return <div className="questionTopicList">{createTopicItem()}</div>;
};

export default QuestionTopicList;
