import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getTranslate } from "../Phrase/PhraseSlice";
import "./QuestionInfo.scss";

const QuestionInfo = (props) => {
  const [contentTextEditorLoaded, setContentTextEditor] = useState(false);
  const [replyTextEditorLoaded, setReplyTextEditor] = useState(false);
 
  useEffect(() => {
    setContentTextEditor(true);
    setReplyTextEditor(true);
  }, []);
  
  function getDiff(target, source) {
    let t = target;
    let s = source;
    if (t != undefined)
      t = t.replace(/<br\/>|<br>/g, "").replace(/\s+/g, "");
    if (s != undefined)
      s = s.replace(/<br\/>|<br>/g, "").replace(/\s+/g, "");;
    let html = "";
    if (props.questionBefore == undefined || t == s) html = target;
    else {
      html = `<strong>${target}</strong>`;
      console.log("");
    }

    return { __html: html };
  }
  return (
    <div  className="d-flex flex-column question-info">
      {/* عنوان */}
      <div className="question-info__Title form-group d-flex">
        <label htmlFor="title">
          {useSelector(getTranslate("AddNewQuestion_Title_Label"))} :
        </label>
        <p
          className="flex-basis: fit-content;"
          dangerouslySetInnerHTML={getDiff(
            props.questionInfo.title,
            props.questionBefore?.title
          )}
        ></p>
      </div>
      {/* گروه */}
      <div className="question-info__group form-group d-flex" >
        <label htmlFor="group">
          {useSelector(getTranslate("AddNewQuestion_Group_Label"))} :
        </label>
        <p>{props.questionInfo.groupTitle}</p>
      </div>
      {/* متن پرسش */}
      <div className="question-info__contentText form-group d-flex">
        <label htmlFor="ContentText">
          {useSelector(getTranslate("AddNewQuestion_ContentText_Label"))} :
        </label>       
        <p
          dangerouslySetInnerHTML={getDiff(
            props.questionInfo.contentText,
            props.questionBefore?.contentText
          )}
        ></p>
      </div>
      {/* متن پاسخ */}
      <div className="question-info__replyText form-group d-flex">
        <label htmlFor="ReplyText">
          {useSelector(getTranslate("AddNewQuestion_ReplyText_Label"))} :
        </label>        
        <p
          dangerouslySetInnerHTML={getDiff(
            props.questionInfo.replyText,
            props.questionBefore?.replyText
          )}
        ></p>
      </div>

      {/* موضوع */}
      <div className="question-info__topic form-group d-flex">
        <label htmlFor="ReplyText">
          {useSelector(getTranslate("QuestionTopic_Placeholder_ChooseTopic"))} :
        </label>
        <p>{props.questionInfo.topic}</p>
      </div>
    </div>
  );
};
export default QuestionInfo;
