import { Fragment } from "react";
import classess from "./QuestionPublishResult.module.scss";
import QuestionsChanged from "./QuestionsChanged";
import QuestionsChangedHeader from "./QuestionsChangedHeader";

const QuestionPublishResult = (props) => {
  console.log("QuestionPublishResult", props.data.data);

  return (
    <>
      {(props?.data?.data ?? []).length > 0 && (
        <>
          <table className=" table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>عنوان</th>
                <th>وضعیت</th>
              </tr>
            </thead>
            <tbody>
              {props.data.data.map((item, index) => (
                <tr key={index}>
                  <td>{item.questionTitle}</td>
                  <td>{item.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {(props?.data?.data ?? []).length === 0 && <>NO DATA</>}
    </>
  );
};

export default QuestionPublishResult;
