import { configureStore } from '@reduxjs/toolkit'
import AccountSlice from '../components/Account/AccountSlice'
import StatusConditionSlice from '../components/Search/StatusConditionSlice'
import PhraseSlice from '../components/Phrase/PhraseSlice'
import NotFoundPageSlice from '../components/NotFound/NotFoundSlice'
export default configureStore({
  reducer: {
    statusConditionSliceReducer:StatusConditionSlice,
    accountSliceReducer:AccountSlice,
    phraseSliceReducer:PhraseSlice,
    notFoundPageReducer:NotFoundPageSlice,
  },
})